import React, { Component } from 'react';
import { Form, FormGroup, FormLabel, FormControl, Button } from 'react-bootstrap';
import 'react-intl-tel-input/dist/main.css';
import { ErrorAlert } from '../common/Alerts';
import FormValidator from '../common/FormValidator';
import { GET_ASYNC, POST_ASYNC } from '../../Consumer';
import Endpoints from '../common/Endpoints';
import '../profile/shared/Profile.scss';
import ResultModal from './modal/ResultModal';

class UserEmailVerification extends Component {

    constructor(props) {
        super(props);

        this.validator = new FormValidator([
            {
                field: 'code',
                method: 'isEmpty',
                validWhen: false,
                message: 'Please enter the 6-digit code'
            }
        ]);

        this.state = {
            form: {
                userId: this.props.location.state.userId !== undefined ? this.props.location.state.userId : undefined,
                code: '',
            },
            validation: this.validator.valid(),
            error: false,
            errorMessages: [],
            show: false,
            verificationText: 'Resend code',
            loading: false,
            showSuccessModal: false
        }
    }

    handleInputChange = (e) => {
        let { name, value } = e.target;

        if (e.target.getAttribute('parent')) {
            let parent = e.target.getAttribute('parent');
            let parentValue = this.state.form[parent];
            parentValue[name] = value;

            name = parent;
            value = parentValue;
        }

        this.setState(prevState => ({
            ...prevState,
            form: {
                ...prevState.form,
                [name]: value
            }
        }));
    }

    validate = (form) => {
        let validation = this.validator.validate(form);
        return validation;
    }

    handleSuccess = () => {
        this.setState({ showSuccessModal: false });
        this.props.history.push({ pathname: "/profile" });
    }

    handleSuccessModalClose = () => this.setState({ showSuccessModal: false });
       
    handleResendCode = async (e) => {
        e.preventDefault();
        this.setState({ loading: true });

        await GET_ASYNC(Endpoints.USER.GET.RESEND_EMAIL_VERIFICATIONCODE + '?userId=' + this.state.form.userId)
            .then(response => response.json())
            .then((result) => {
                if (result.error) {
                    if (result.data !== null) this.setState({ error: false, errorMessages: result.data.message, form: { code: '', userId: result.data.userID }, loading: false });
                    else this.setState({ error: true, errorMessages: result.message, form: { code: '' }, loading: false });
                }
                else{
                    this.setState({ error: false, errorMessages: result.data.message, verificationText: 'New verification code sent', form: { code: '', userId: result.data.userID }, loading: false });
                } 

            }).catch(() => this.setState({ errorMessages: ["There has been an issue resending the code."], loading: false }));
    }

    handleSubmit = async (e) => {
        e.preventDefault();
        this.setState({ loading: true });

        const { form } = this.state;
        const validation = this.validate(form);
        this.setState({ validation: validation, submitted: true });

        if (validation.isValid) {
            await POST_ASYNC(Endpoints.USER.POST.VERIFY_EMAIL_CODE, form)
                .then(response => response.json())
                .then((result) => {
                    if (result.error) {
                        if (result.data !== null) this.setState({ warning: result.data.warning ?? false, errorMessages: result.data.errorMessages ?? [], loading: false });
                        else this.setState({ errorMessages: result.message, loading: false });
                    }
                    else this.setState({showSuccessModal: true})
                })
                .catch(() => this.setState({ errorMessages: ["There has been an issue during submission."], loading: false }));
        } else {
            this.setState({ loading: false });
        }
    }

    render() {
        let { verificationText, errorMessages, heading, validation, form, showSuccessModal } = this.state;

        return (
            <Form onSubmit={this.handleSubmit}>
                <div className="main-content editEmail-address">
                        <h3 className="mb-3">Your Profile</h3>
                        {errorMessages.length > 0 && <ErrorAlert messages={errorMessages} heading={heading} />}
                        <h4>Verify New Email</h4>
                        <FormGroup className='mb-5'>
                            <FormLabel htmlFor="verificationCodeField">Verification Code</FormLabel>
                            <FormControl id="emailField" className={validation.code.isInvalid ? "input-error" : undefined} type="text" name="code" placeholder="Enter the 6-digit code" onChange={this.handleInputChange} value={form.code} />
                            {validation.code.message.length > 0
                        ? <div className="errorSpan">
                            <span className="text-danger">{validation.code.message}</span>
                        </div>
                        : undefined}

                            <div className='button-container'>
                                <Button variant="secondary" block onClick={this.handleResendCode}>{verificationText}</Button>                       
                                <Button variant="primary" type="submit" value="Submit">Validate code</Button>                  
                            </div>                                                     
                        </FormGroup>
                </div>
                <ResultModal title={"Success"} text={"Email Address Has Been Updated"} buttonText={"Ok"} handleClose={this.handleSuccessModalClose} handleConfirmAction={this.handleSuccess} show={showSuccessModal} large={false} showCloseButton={false} />
            </Form>
        )
    }
}

export default UserEmailVerification;