import React from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import LoadingBar from '../../common/LoadingBar';
import { NoReturns, NoReturnsSearch } from '../shared/Snippets';
import PagingTable from '../../common/tables/PagingTable';
import { formatDate, getStatusClass } from '../../../Utilities';
import { GET_ASYNC } from '../../../Consumer';
import Endpoints from '../../common/Endpoints';
import { RETURN_METHODS, STATUSES_HOME_COLLECTION, STATUSES_DROPOFF_RETAILER, ORDERING_FILTER_HOME_COLLECTION, ORDERING_FILTER_DROPOFF, ORDERING_FILTER_DEFAULT } from '../../common/constants';
import { WarningAlert } from '../../common/Alerts';
import { isEmpty } from '../../../Utilities';
import { RetailerReturnsFilter } from '../retailer/RetailerReturnsFilter';

const HEADERS = ["Order ID", "Customer Name", "Return Robin ID", "Date Created", "Collection/Drop-Off Date", "Service", "Status"];
const SERVICE_SELECT_TITLE = "Return Service Selection";
const SERVICE_SELECT_MESSAGE = "Please select a returns service to become an active retailer.";

const ViewReturn = ({ id, history, orderID }) => {
    return <Button variant="link" className="m-0 underline btn btn-link" onClick={() => history.push(`/retailer/return/details/${id}`)}>{orderID}</Button>;
};

const initialFilter = {
    searchField: "",
    dateCreatedDescending: true,
    dateOfCollectionDescending: null,
    dateOfDropOffDescending: null,
    returnMethod: "",
    isReturnMethodSelected: false,
    status: "",
    statusesList: [],
    orderingList: ORDERING_FILTER_DEFAULT,
    orderingListByMethod: [],
    ordering: ""
};

class Returns extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            pageIndex: 1,
            pageCount: 1,
            returns: [],
            filter: initialFilter,
            sort: "DateCreatedDesc",
            firstRender: true,
            hasServiceSelected: props.hasServiceSelected
        };
    }

    async componentDidMount() {
        await this.getReturns();
    }

    getReturns = async () => {
        const { pageIndex } = this.state;
        const filter = { ...this.state.filter };
        let url = new URL(Endpoints.RETURN.GET.RETAILER_RETURNS);

        Object.keys(filter).forEach(k => url.searchParams.append(k, filter[k]));
        url.searchParams.append("pageIndex", pageIndex);

        await GET_ASYNC(url)
            .catch(error => console.log(error))
            .then(response => {
                if (response.ok) return response.json();
            })
            .then(results => {
                const returns = results ? results.data.results : [];
                const pageCount = results ? results.data.pageCount : 0;
                this.setState({ returns: returns, pageCount: pageCount, loading: false });
            });
    }

    getReturnRows = () => {
        const { returns } = this.state;
        const returnRows = [];
        
        returns.forEach(async r => await returnRows.push([
            <ViewReturn id={r.returnID} orderID={r.orderReference ? r.orderReference : r.orderID} history={this.props.history} />,
            r.customerName,
            r.uniqueReturnID, 
            formatDate(r.createdDate),
            r.returnMethod === 0 ? formatDate(r.preferredCollectionDateTime) : r.scannedDateTime === null ? "-" : formatDate(r.scannedDateTime),
            RETURN_METHODS[r.returnMethod],
            <span className={getStatusClass(r.returnStatusValue)}>{r.returnStatusValue}</span>
        ]));
            
        return returnRows;
    }

    onNext = async () => {
        const { pageIndex, pageCount } = this.state;
        if (pageIndex < pageCount) {
            this.setState({ pageIndex: parseInt(pageIndex) + 1, loading: true, firstRender: false }, await this.getReturns);
        }
    }

    onEnd = async () => {
        const { pageIndex, pageCount } = this.state;
        if (pageIndex < pageCount) {
            this.setState({ pageIndex: pageCount, loading: true, firstRender: false }, await this.getReturns);
        }
    }

    onPrevious = async () => {
        const { pageIndex } = this.state;
        if (pageIndex > 1) {
            this.setState({ pageIndex: parseInt(pageIndex) - 1, loading: true, firstRender: false }, await this.getReturns);
        }
    }

    onStart = async () => {
        const { pageIndex } = this.state;
        if (pageIndex > 1) {
            this.setState({ pageIndex: 1, loading: true, firstRender: false }, await this.getReturns);
        }
    }

    onClear = async (e) => {
        e.preventDefault();
        this.setState({
            filter: initialFilter,
            firstRender: false,
            loading: true,
            sort: "DateCreatedDesc"
        }, await this.getReturns);
    }

    onSearch = async (e) => {
        e.preventDefault();
        this.setState({ loading: true, firstRender: false });
        await this.getReturns();
    }

    onFilterChange = async (e) => {
        const { name, value } = e.target;

        if (name === "sort") {
            this.setState({ sort: value });

            switch (value) {
                case "DateCreatedAsc":
                    this.setState(prevState => ({
                        filter: {
                            ...prevState.filter,
                            dateCreatedDescending: false,
                        }
                    }));
                    break;
                    
                default:
                    this.setState(prevState => ({
                        filter: {
                            ...prevState.filter,
                            dateCreatedDescending: true,
                        }
                    }));
                    break;
            }
        } 
        else if (name === "returnMethod") {
            await this.returnMethodFilterUpdate(value);
        }
        else if (name === "ordering") {
            this.setState(prevState => ({
                filter: {
                    ...prevState.filter,
                    ...{ [name]: value }
                }
            }));

            switch (value) {
                case "DropOffDateAsc":
                    this.setState(prevState => ({
                        filter: {
                            ...prevState.filter,
                            dateCreatedDescending: null,
                            dateOfCollectionDescending: null,
                            dateOfDropOffDescending: false
                        }
                    }));
                    break;

                case "DropOffDateDesc":
                    this.setState(prevState => ({
                        filter: {
                            ...prevState.filter,
                            dateCreatedDescending: null,
                            dateOfCollectionDescending: null,
                            dateOfDropOffDescending: true,
                        }
                    }));
                    break;

                case "CollectionDateAsc":
                    this.setState(prevState => ({
                        filter: {
                            ...prevState.filter,
                            dateCreatedDescending: null,
                            dateOfCollectionDescending: false,
                            dateOfDropOffDescending: null,
                        }
                    }));
                    break;

                case "CollectionDateDesc":
                    this.setState(prevState => ({
                        filter: {
                            ...prevState.filter,
                            dateCreatedDescending: null,
                            dateOfCollectionDescending: true,
                            dateOfDropOffDescending: null,
                        }
                    }));
                    break;
                
                default:
                    this.setState(prevState => ({
                        filter: {
                            ...prevState.filter,
                            dateCreatedDescending: null,
                            dateOfCollectionDescending: null,
                            dateOfDropOffDescending: null,
                        }
                    }));
                    break;
            }
        }
        // else if (name === "searchField" && value === "" && this.state.filter.returnMethod === "")
        else if (name === "searchField" && value === "")
        {
            this.state.filter.searchField= "" ;
            await this.onSearch(e);
        }
        else {
            this.setState(prevState => ({
                filter: {
                    ...prevState.filter,
                    ...{ [name]: value }
                }
            }));
        }
    }

    returnMethodFilterUpdate = async (value) => {
        const returnMethod = value !== "" ? parseInt(value) : "";

        var statusesList = [];
        var orderingListByMethod = [];
        switch (returnMethod) {
            case 0:
                statusesList = STATUSES_HOME_COLLECTION;
                orderingListByMethod = ORDERING_FILTER_HOME_COLLECTION;
                break;
            case 1:
                statusesList = STATUSES_DROPOFF_RETAILER;
                orderingListByMethod = ORDERING_FILTER_DROPOFF;
                break;
            default:
                statusesList = [];
                orderingListByMethod = [];
        }

        this.setState(prevState => ({
            filter: {
                ...prevState.filter,
                returnMethod: returnMethod,
                statusesList: statusesList,
                status: "",
                isReturnMethodSelected: !isEmpty(returnMethod),
                orderingListByMethod: orderingListByMethod,
                ordering: "",
                dateOfCollectionDescending: null,
                dateOfDropOffDescending: null,
            }

        }));
    }

    handleKeyPress= async (e) => {
        if (e.key === 'Enter') { 
            await this.onSearch(e);
        }       
    }

    handleIconClicked= async (e) => {
            await this.onSearch(e);    
    }

    render() {

        const { loading, returns, firstRender, filter, sort, pageIndex, pageCount, hasServiceSelected } = this.state;
        const noReturns = returns.length === 0;

        return (
            <div className="main-content">
                {loading && <LoadingBar />}
                <Row>
                    <Col sm={12} md={6}>
                        {!hasServiceSelected && <WarningAlert heading={SERVICE_SELECT_TITLE} message={SERVICE_SELECT_MESSAGE} />}
                    </Col>
                </Row>             
                <h3>Returns</h3>
                <h4 className="mb-3">Return Log</h4>
                {(noReturns && firstRender) ? <NoReturns />
                    : <React.Fragment>
                        <RetailerReturnsFilter filter={filter} sort={sort} onFilterChange={this.onFilterChange} onClear={this.onClear} onSearch={this.onSearch} handleKeyPress={this.handleKeyPress} handleIconClicked={this.handleIconClicked} />
                        {noReturns && <NoReturnsSearch />}
                        <PagingTable headers={HEADERS} rows={this.getReturnRows()} onNext={this.onNext} onPrevious={this.onPrevious} onStart={this.onStart} onEnd={this.onEnd} pageIndex={pageIndex} pageCount={pageCount} />
                    </React.Fragment>}
            </div>
        );
    }
}

export default Returns;