import React, { Component } from 'react';
import { Form, FormGroup, FormLabel, FormControl, Button } from 'react-bootstrap';
import 'react-intl-tel-input/dist/main.css';
import { ErrorAlert } from '../common/Alerts';
import FormValidator from '../common/FormValidator';
import { POST_ASYNC } from '../../Consumer';
import Endpoints from '../common/Endpoints';
import '../profile/shared/Profile.scss';

class EditEmailAddress extends Component {

    constructor(props) {
        super(props);

        this.validator = new FormValidator([
            {
                field: 'email',
                method: 'isEmail',
                validWhen: true,
                message: 'This is not a valid email address, try again.'
            }
        ]);

        this.state = {
            form: {
                email: '',
                confirmation: false
            },
            userId: this.props.location.userID !== undefined ? this.props.location.userID : undefined,
            validation: this.validator.valid(),
            warning: false,
            errorMessages: [],
            heading: '',
            submitted: false,
            loading: false,
            confirmationValidation: true,
        };
    }

    handleInputChange = (e) => {
        let { name, value } = e.target;

        if (e.target.getAttribute('parent')) {
            let parent = e.target.getAttribute('parent');
            let parentValue = this.state.form[parent];
            parentValue[name] = value;

            name = parent;
            value = parentValue;
        }

        this.setState(prevState => ({
            ...prevState,
            form: {
                ...prevState.form,
                [name]: value
            }
        }));
    }

    validate = (form) => {
        let validation = this.validator.validate(form);
        return validation;
    }

    handleSubmit = async (e) => {
        e.preventDefault();
        this.setState({ loading: true });

        const { form } = this.state;
        const validation = this.validate(form);
        this.setState({ validation: validation, submitted: true });

        if (validation.isValid) {
            const vm = {
                UserID: this.state.userId,
                EmailAddress: this.state.form.email
            };

            await POST_ASYNC(Endpoints.USER.POST.VERIFY_EMAILADDRESS, vm)
                .then(response => response.json())
                .then((result) => {
                    if (result.error) {
                        if (result.data !== null) this.setState({ warning: result.data.warning ?? false, errorMessages: result.data.errorMessages ?? [], loading: false });
                        else this.setState({ errorMessages: result.message, loading: false });
                    }
                    else this.props.history.push({ pathname: "/profile/emailverification", state: { userId: result.data.id } });
                })
                .catch(() => this.setState({ errorMessages: ["There has been an issue during submission."], loading: false }));
        } else this.setState({ loading: false });
    }

    render() {
        let { errorMessages, heading, validation, form } = this.state;
        const emailClasses = validation.email.isInvalid ? "input-error" : undefined;

        return (
            <Form onSubmit={this.handleSubmit}>
                <div className="main-content editEmail-address">
                        <h3 className="mb-3">Your Profile</h3>
                        {errorMessages.length > 0 && <ErrorAlert messages={errorMessages} heading={heading} />}
                        <h4>Edit Email Address</h4>
                        <FormGroup className='mb-5'>
                            <FormLabel htmlFor="emailField">Email Address</FormLabel>
                            <FormControl id="emailField" className={emailClasses} type="email" name="email" maxLength="50" onChange={this.handleInputChange} value={form.email} />
                            <span className="text-danger">{validation.email.message}</span>

                            <div className='button-container'>
                                <Button variant="secondary" onClick={() => this.props.history.push('/profile')}>Cancel</Button>                       
                                <Button variant="primary" type="submit" value="Submit">Save</Button>                  
                            </div>                                                     
                        </FormGroup>
                </div>
            </Form>
        )
    }
}

export default EditEmailAddress;