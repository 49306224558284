import React, { Component, Fragment } from 'react';
import { FormGroup, FormControl, FormLabel } from 'react-bootstrap';
import { Row, Col, Button } from 'react-bootstrap';
import moment from 'moment-timezone';
import FormValidator from '../../../common/FormValidator';
import Endpoints from '../../../common/Endpoints';
import { GET, GET_ASYNC, POST, POST_ASYNC } from '../../../../Consumer';
import Stepper from '../../../common/wizard/Stepper';
import StepperButtons from '../../../common/wizard/StepperButtons';
import CreateReturnRetailerDetails from './CreateReturnRetailerDetails';
import CreateReturnAddress from './CreateReturnAddress';
import CreateReturnProductDetails from './CreateReturnProductDetails';
import CreateReturnDetails from './CreateReturnDetails';
import CreateReturnSummary from './CreateReturnSummary';
import CreateReturnConfirmation from './CreateReturnConfirmation';
import CreateReturnLocationLookup from './CreateReturnLocationLookup';
import { ReturnOrderDetails, ReturnOrderSelazarIntDetails } from './CreateReturnOrderDetails';
import LoadingBar from '../../../common/LoadingBar';
import ReturnPermissionDenied from './ReturnPermissionDenied';
import Confirm from '../../../common/modals/Confirm';
import { ErrorAlert } from '../../../common/Alerts';
import './ReturnWizard.scss';
import { BankHolidays } from '../../shared/BankHolidays';

const ERROR_RETURN_METHOD_UNAVAILABLE = "Unfortunately this return method is no longer available. Please try creating a new return using an alternative method.";
const ERROR_NO_RETURN_METHODS = "Unfortunately this return method is no longer available. Please contact the retailer for advice.";
const CANCEL_TEXT = "Cancel Create Return";
const CANCEL_LINK = "/";
const REASON_OTHER = "Other";
const weightAlert = "(10kg max weight)";

const ActiveStep_SelectRetailer = "SelectRetailer";
const ActiveStep_ReturnMethod = "ReturnMethod";
const ActiveStep_LocationLookUp = "LocationLookUp";
const ActiveStep_CollectionAddress = "CollectionAddress";
const ActiveStep_OrderDetails = "OrderDetails";
const ActiveStep_ReturnReason = "ReturnReason";
const ActiveStep_CollectionDate = "CollectionDate";
const ActiveStep_ReturnInfo = "ReturnInfo";
const ActiveStep_ReturnSummary = "ReturnSummary";

const defaultReturnWizardDetails = {
    id: "",
    payload: {
        retailerDetails: {
            id: "",
            name: ""
        },
        addressDetails: {
            id: "",
            summary: ""
        },
        orderDetails: {
            emailAddress: "",
            orderID: "",
            orderDate: ""
        },
        selazarOrderDetails: {
            orderID: "",
            emailAddress: "",
            orderReference: "",
            products: [],
            orderDate: ""
        },
        productDetails: {
            returnReason: "",
            additionalInformation: ""
        },
        returnDetails: {
            collectionDate: ""
        },
        parcelConfirmationDetails: {
            confirmation: false
        }
    }
};

let collectionMinDate = new Date();
collectionMinDate.setDate(collectionMinDate.getDate() + 2);
let acceptableDate = new Date();
acceptableDate.setDate(collectionMinDate.getDate() - 1);
let maxDate = new Date();
maxDate.setDate(maxDate.getDate());

class CreateReturnWizard extends Component {

    retailerValidator = new FormValidator([
        {
            field: 'id',
            method: 'isEmpty',
            validWhen: false,
            message: 'Please select a retailer'
        }
    ]);

    orderValidator = new FormValidator([
        {
            field: 'orderDate',
            method: 'isEmpty',
            validWhen: false,
            message: 'Enter a valid order date to continue'
        },
        {
            field: 'orderID',
            method: 'isEmpty',
            validWhen: false,
            message: 'Enter your order ID to continue'
        },
        {
            field: 'emailAddress',
            method: 'isEmail',
            validWhen: true,
            message: 'Enter the email address associated with your order to continue'
        }
    ]);

    selazarOrderValidator = new FormValidator([
        {
            field: 'orderReference',
            method: 'isEmpty',
            validWhen: false,
            message: 'Enter your order reference to continue'
        },
        {
            field: 'emailAddress',
            method: 'isEmail',
            validWhen: true,
            message: 'Enter the email address associated with your order to continue'
        }
    ]);

    productValidator = new FormValidator([
        {
            field: 'returnReason',
            method: 'isEmpty',
            validWhen: false,
            message: 'Select a return reason to continue'
        }
    ]);

    productReasonAdditionalInformationValidator = new FormValidator([
        {
            field: 'additionalInformation',
            method: 'isEmpty',
            args: [ { ignore_whitespace: true } ],
            validWhen: false,
            message: 'Enter the additional information to continue'
        }
    ]);

    returnValidator = new FormValidator([
        {
            field: 'collectionDate',
            method: 'isAfter',
            args: [acceptableDate.toDateString()],
            validWhen: true,
            message: 'Select a collection date to continue'
        }
    ]);

    radioButtonValidation = {
        isValid: false,
        validationMessage: ""
    };

    mapValidation = {
        isValid: false,
        validationMessage: ""
    };

    state = {
        pageIndex: 1,
        pageCount: 1,
        currentPage: [],
        steps: [ActiveStep_SelectRetailer, ActiveStep_ReturnMethod, ActiveStep_CollectionAddress, ActiveStep_OrderDetails, ActiveStep_ReturnReason, ActiveStep_CollectionDate, ActiveStep_ReturnInfo, ActiveStep_ReturnSummary],
        activeStep: ActiveStep_SelectRetailer,
        show: false,
        showReturnMethodErrorModal: false,
        returnMethodError: "",
        returnWizardDetails: defaultReturnWizardDetails,
        summary: {
            selectedAddress: "",
            selectedRetailer: "",
            emailAddress: "",
            orderId: "",
            orderReference: "",
            orderDate: new Date(),
            returnReason: "",
            collectionDate: new Date(),
            returnMethod: 0
        },
        selectedAddressFull: [],
        userAddresses: [],
        error: false,
        errorMessages: [],
        showSubmitErrorModal: false,
        loading: true,
        hasValidSelazarIntegration: false,
        retailerDetailsValidation: this.retailerValidator.valid(),
        orderDetailsValidation: this.orderValidator.valid(),
        selazarOrderValidation: this.selazarOrderValidator.valid(),
        productDetailsValidation: this.productValidator.valid(),
        productReasonAdditionalInformationValidation: this.productReasonAdditionalInformationValidator.valid(),
        returnDetailsValidation: this.returnValidator.valid(),
        parcelConfirmationValidation: true,
        permitted: false,
        permittedErrors: [],
        orderDate: "",
        retailerServices: [],
        isHomeCollection: false,
        isDropOff: false,
        showOrderAgeLimitError: false,
        orderProductDetails: {},
        showProducts: false,
        showProductsOrderAgeError: false,
        showProductsError: false,
        showProductSelectError: false,
        showCollectPlusMap: false,
        collectPlusDropOffSearchError: {
            visible: false,
            message: ""
        },
        selectedOption: null,
        isNextDisabled: true,
        collectPlusDropOffPoints: [],
        collectPlusSearchLocation: {},
        collectPlusDropOffPostCodeText: "",
        buttonValidation: "",
        createdReturnID: null,
        createdReturnDetails: {
            selectedRetailer: "",
            emailAddress: "",
            orderID: "",
            orderDate: null,
            itemName: "",
            size: "",
            returnReason: "",
            additionalInformation: "",
            returnRobinId: "",
            createdDate: null,
            status: "",
            orderReference: "",
            products: [],
            returnMethod: 0,
            returnDropOffInformation: {
                barcodeContents: "",
                courierTrackingReference: "",
                courierConsignmentNumber: "",
                customerReference: "",
                dateBooked: null
            },
            returnCollectionInformation: {
                collectionDate: null,
                selectedAddress: "",
                selectedAddressFull: [],
                postcode: "",
                canCancel: false,
                trackingReference: ""
            }
        },
        bankHolidays: []
    };

    async componentDidMount() {
        await this.checkReturnsLimit();
        try{
          const response = await BankHolidays();
            if (response?.ok) {
                const result = await response.json();
                if (result.error) {
                    result.data !== null
                        ? this.setState({errorMessages: result.data})
                        : this.setState({errorMessages: result.message});
                } else {
                    const bankHolidays = result.data ? result.data : [];
                    this.setState({bankHolidays});
                }
            }
        }
        catch (error) {
            console.log(error);
        }
        
        this.setState({ loading: false });
    }

    // API Calls
    checkReturnsLimit = async () =>
        GET(Endpoints.RETURN.GET.CHECK_PERMITTED)
            .then(response => response.json())
            .then(result => {
                if (result.error) {
                    result.data
                        ? this.setState({ permittedErrors: result.data })
                        : this.setState({ permittedErrors: result.message });
                } else {
                    const data = result.data;
                    this.setState({ permitted: data })
                }
            }).catch(() => this.setState({ permittedErrors: ["There has been an issue starting the return."] }));

    getRetailerServices = async () => {
        const { returnWizardDetails, isHomeCollection } = this.state;
        const retailerID = returnWizardDetails.payload.retailerDetails.id;

        try {
            const response = await GET_ASYNC(Endpoints.RETURN.GET.RETURN_SERVICE_BY_RETAILER + retailerID)
            if(response?.ok) {
                const result = await response.json();
                if (result.error) {
                    result.data
                        ? this.setState({ errorMessages: result.data })
                        : this.setState({ errorMessages: result.message });
                } else {
                    const services = result.data ? result.data : [];
                    
                    if(services.length === 2) {
                        this.setState({ 
                            steps: [ActiveStep_SelectRetailer, ActiveStep_ReturnMethod, ActiveStep_CollectionAddress, ActiveStep_OrderDetails, ActiveStep_ReturnReason, ActiveStep_CollectionDate, ActiveStep_ReturnInfo, ActiveStep_ReturnSummary],
                            retailerServices: services,
                            isNextDisabled: false 
                        })

                        this.fetchAddresses();
                    }
                    else if(services.length > 0) {
                        var selectedServices = [];

                        for (var i = 0; i < services.length; i++) {
                            selectedServices.push({
                                servicesType: services[i].servicesType,   
                                isActive: services[i].isActive });

                            if(services[i].servicesType === 0) {
                                this.setState({ 
                                    isHomeCollection: true,
                                    steps: [ActiveStep_SelectRetailer, ActiveStep_CollectionAddress, ActiveStep_OrderDetails, ActiveStep_ReturnReason, ActiveStep_CollectionDate, ActiveStep_ReturnInfo, ActiveStep_ReturnSummary],
                                    selectedOption: services[i].servicesType,
                                    isNextDisabled: false
                                })
                            }
                            else {
                                this.setState({ 
                                    isDropOff: true,
                                    steps: [ActiveStep_SelectRetailer, ActiveStep_LocationLookUp, ActiveStep_OrderDetails, ActiveStep_ReturnReason, ActiveStep_ReturnInfo, ActiveStep_ReturnSummary],
                                    selectedOption: services[i].servicesType,
                                    isNextDisabled: false
                                });
                            }
                        }

                        this.setState(prevState => ({
                            retailerServices: selectedServices,
                            returnWizardDetails: {
                                ...prevState.returnWizardDetails,
                                payload: {
                                    ...prevState.returnWizardDetails.payload,
                                    returnMethod: isHomeCollection ? 0 : 1
                                }
                            }
                        }));

                        this.fetchAddresses();
                    }
                    else this.setState({ errorMessages: ["Retailer does not have any return service selected."], retailerServices: [], loading: false });
                }
            }
            else this.setState({ errorMessages: response.status });
        } catch (error) {
            console.log(error);
            this.setState({ errorMessages: ["There has been an issue returning the retailer services."] })
        }
    }

    fetchAddresses = async () =>
        GET(Endpoints.ADDRESS.GET.ADDRESSES_BY_USERID)
            .then(response => response.json())
            .then(result => {
                if (result.error) {
                    if (result.data) this.setState({ errorMessages: result.data });
                    else this.setState({ errorMessages: result.message });
                }
                else {
                    const userAddresses = result.data ? result.data : [];
                    if (userAddresses.length > 0) {
                        const defaultAddress = userAddresses.find(a => a.default === true);

                        if (defaultAddress !== undefined) {
                            this.handleAddressSelection(defaultAddress.id, defaultAddress.address);
                        }
                    }
                    this.setState({ userAddresses: userAddresses });
                }
            }).catch(() => this.setState({ errorMessages: ["There has been an issue retrieving addresses."] }));

    hasValidSelazarIntegration = async () => {
        const { returnWizardDetails } = this.state;
        const retailerId = returnWizardDetails.payload.retailerDetails.id;

        await GET_ASYNC(new URL(retailerId, Endpoints.SETTINGS.GET.VALID_SELAZAR_INTEGRATION))
            .then(response => {
                if (response.ok) return response.json();
            })
            .then(result => {
                const hasValidSelazarIntegration = result ? result.data : false;
                this.setState({ hasValidSelazarIntegration: hasValidSelazarIntegration, loading: false  });
            })
            .catch(error => console.log(error));
    }

    fetchSelazarOrderDetails = async () => {
        const { returnWizardDetails } = this.state;
        const retailerId = returnWizardDetails.payload.retailerDetails.id;
        const searchParameters = {
            orderReference: returnWizardDetails.payload.selazarOrderDetails.orderReference,
            returnEmail: returnWizardDetails.payload.selazarOrderDetails.emailAddress};

        await POST_ASYNC(Endpoints.RETURN.POST.SELAZAR_ORDER_PRODUCTS + retailerId, searchParameters)
            .then(response => {
                if (response.ok) return response.json();
            })
            .then(result => {
                const orderDetails = result ? result.data : null;

                if (orderDetails === null) {
                    this.setState({ showProductsError: true, showProductsOrderAgeError: false });
                } else {
                    orderDetails?.orderLimitReached
                        ? this.setState({ showProductsError: false, showProductsOrderAgeError: true })
                        : this.setState({ orderProductDetails: { orderDate: orderDetails.createdDate, products: orderDetails.items }, 
                            showProducts: true, showProductsError: false, showProductsOrderAgeError: false });
                    
                        //set orderID within current selazarOrderDetails state
                        this.setState(prevState => ({
                            ...prevState,
                            returnWizardDetails: {
                                ...prevState.returnWizardDetails,
                                payload: {
                                    ...prevState.returnWizardDetails.payload, 
                                    selazarOrderDetails: {
                                       ...prevState.returnWizardDetails.payload.selazarOrderDetails,
                                       orderID: orderDetails.orderID,
                                       orderDate: orderDetails.createdDate
                                    }
                                }
                            }
                        }))

                }
            })
            .catch(error => console.log(error));
    }

    fetchValidOrderLimit = async (orderAgeLimitRetailer) =>
        POST(Endpoints.RETURN.POST.LIMIT, orderAgeLimitRetailer)
            .then(response => response.json())
            .then(result => {
                return result ? result.data : false;
            })
            .catch(error => {
                console.log(error);
                return false;
            });

    fetchCollectPlusDropOffPoints = async (postcode) => 
        GET_ASYNC(Endpoints.RETURN.GET.COLLECT_PLUS_DROP_OFF_POINTS + postcode)
        .then(response => response.json())
        .then(result => {
            const collectPlusLocations = result ? result.data : null;
            if(collectPlusLocations.agents !== null){
                this.setState({ collectPlusDropOffPoints: collectPlusLocations.agents, 
                    collectPlusSearchLocation: { lat : Number(collectPlusLocations.searchLocationLatitude), lng: Number(collectPlusLocations.searchLocationLongitude)},
                    collectPlusDropOffPostCodeText: "",
                    collectPlusDropOffSearchError: { visible: false, message: "" },
                    showCollectPlusMap: true
                    });
            }
            else {
                this.handleCollectPlusLocationSearchIssue("No locations found.");
            }
        })
        .catch(error => {
            console.log(error);
            this.handleCollectPlusLocationSearchIssue("Unfortunately there has been an issue retrieving drop-off points.");
        });

    saveStep = async (url, payload, moveStep = true) => {
        const { returnWizardDetails } = this.state;
        const wizardID = returnWizardDetails.id ? returnWizardDetails.id : "";

        return POST(`${url}/${wizardID}`, payload)
            .then(response => response.json())
            .then((result) => {
                if (result.error) {
                    if (result.data) this.setState({ errorMessages: result.data });
                    else this.setState({ errorMessages: result.message });
                }
                else {
                    this.setState(prevState => ({
                        ...prevState,
                        returnWizardDetails: {
                            ...prevState.returnWizardDetails,
                            id: result.data
                        },
                        errorMessages: []
                    }));

                    if (moveStep) this.nextStep();
                }
            }).catch(() => this.setState({ errorMessages: ["There has been an issue saving this step."] }));;
    }

    retrieveSummary = () => {
        this.setState({ loading: true });
        const { returnWizardDetails } = this.state;
        const wizardID = returnWizardDetails.id ? returnWizardDetails.id : "";

        return GET(`${Endpoints.RETURN.GET.SUMMARY}/${wizardID}`)
            .then(response => response.json())
            .then((result) => {
                if (result.error) {
                    if (result.data) this.setState({ errorMessages: result.data });
                    else this.setState({ errorMessages: result.message });
                }
                else {
                    this.setState(prevState => ({
                        ...prevState,
                        summary: result.data
                    }));
                    this.setState({ loading: false });
                }
            }).catch(() => this.setState({ errorMessages: ["There has been an issue retrieving the summary."], loading: false }));
    }

    retrieveReturn = () => {
        const { createdReturnID } = this.state;
        this.setState({ loading: true });

        return GET(Endpoints.RETURN.GET.RETURN_DETAILS + createdReturnID)
            .then(response => response.json())
            .then((result) => {
                if(result.error){
                    if (result.data) this.setState({ errorMessages: result.data });
                    else this.setState({ errorMessages: result.message });
                } else {
                    const returnDetails = result.data;

                    this.setState({ 
                        createdReturnDetails: { 
                            selectedRetailer: returnDetails.selectedRetailer, 
                            emailAddress: returnDetails.emailAddress,
                            orderID: returnDetails.orderID,
                            orderDate: returnDetails.orderDate,
                            itemName: returnDetails.itemName,
                            size: returnDetails.size,
                            returnReason: returnDetails.returnReason,
                            additionalInformation: returnDetails.additionalInformation,
                            returnRobinId: returnDetails.returnRobinId,
                            createdDate: returnDetails.createdDate,
                            status: returnDetails.status,
                            orderReference: returnDetails.orderReference,
                            products: returnDetails.products,
                            returnMethod: returnDetails.returnMethod,
                            returnDropOffInformation: returnDetails.returnDropOffInformation, 
                            returnCollectionInformation: returnDetails.returnCollectionInformation
                        }
                    });
                }
                
                this.setState({ loading: false });

            }).catch(() => this.setState({ loading: false }));
    }

    handleSubmit = async () => {
        this.setState({ loading: true });
        const { returnWizardDetails, selectedOption } = this.state;

        // Validate parcel confirmation
        this.setState({ parcelConfirmationValidation: returnWizardDetails.payload.parcelConfirmationDetails.confirmation });

        if (returnWizardDetails.payload.parcelConfirmationDetails.confirmation) {
            await this.getRetailerServices();

            const { retailerServices } = this.state;
            const retailerService = retailerServices.find(s => s.servicesType === selectedOption);
            if (retailerService !== undefined && retailerService.isActive) await this.submitReturn();
            else
                this.setState({
                    returnWizardDetails: defaultReturnWizardDetails,
                    returnMethodError: retailerServices.length === 0 ? ERROR_NO_RETURN_METHODS : ERROR_RETURN_METHOD_UNAVAILABLE,
                    errorMessages: [],
                    showReturnMethodErrorModal: true
                });
        }
        
        this.setState({ loading: false });
    }

    submitReturn = async () => {
        const { returnWizardDetails } = this.state;
        const wizardID = returnWizardDetails.id ? returnWizardDetails.id : "";
        await this.saveStep(Endpoints.RETURN.POST.SAVE_PARCEL_CONFIRMATION, returnWizardDetails.payload.parcelConfirmationDetails, false);

        await POST_ASYNC(`${Endpoints.RETURN.POST.SUBMIT}/${wizardID}`)
            .then(response => response.json())
            .then(result => {
                if (result.error) {
                    if (result.data) this.setState({ errorMessages: result.data });
                    else this.setState({ showSubmitErrorModal: true });
                }
                else {
                    this.setState({ createdReturnID: result.data })
                    this.nextStep();
                }
            }).catch(() => this.setState({ showSubmitErrorModal: true }));
    }

    // Modal
    handleShow = (e) => {
        e.preventDefault();
        this.setState({ show: true });
    }

    handleClose = () => this.setState({ show: false });

    handleCloseModal = (e) => {
        e.preventDefault();
        this.setState({ show: false });
    }

    handleCancelAccount = (e) => {
        e.preventDefault();
        this.props.history.push("/");
    }

    handleCloseSubmitErrorModal = (e) => {
        e.preventDefault();
        this.setState({ showSubmitErrorModal: false });
        this.props.history.push("/");
    }

    handleReturnMethodModal = async (e) => {
        e.preventDefault();
        this.setState({ activeStep: ActiveStep_SelectRetailer, showReturnMethodErrorModal: false, returnMethodError: "" });
    }

    // Access Denied
    handleOkButton = () => this.props.history.push('/return');

    // null or error response
    handleCollectPlusLocationSearchIssue = (message) => {
        this.setState({ collectPlusDropOffPoints: [], collectPlusDropOffSearchError: { visible: true, message: message}, showCollectPlusMap: false, collectPlusDropOffPostCodeText: "" });
    }

    // Stepper
    handleNext = async () => {
        const { activeStep, returnWizardDetails, orderDate, hasValidSelazarIntegration, showProducts, selectedOption, 
            isHomeCollection, isDropOff } = this.state;
        this.setState({ loading: true });
        let validate = null;
        let updatedOrderDetails = {};

        if (activeStep === ActiveStep_SelectRetailer) {
            validate = await this.validateRetailerDetails(returnWizardDetails.payload.retailerDetails);

            if (validate.isValid) await this.hasValidSelazarIntegration();
        }
        // Home collection route
        else if (activeStep === ActiveStep_ReturnMethod) {
            validate = await this.validateRadioButtons(selectedOption);
        }
        else if (activeStep === ActiveStep_CollectionAddress) {
            validate = await this.validateAddressDetails(returnWizardDetails.payload.addressDetails);
        }
        else if (activeStep === ActiveStep_OrderDetails) {
            if (hasValidSelazarIntegration) {
                validate = await this.validateSelazarOrderDetails(returnWizardDetails.payload.selazarOrderDetails);

                if (validate.isValid && !showProducts) {
                    await this.fetchSelazarOrderDetails();
                } else if (validate.isValid && showProducts && returnWizardDetails.payload.selazarOrderDetails.products.length === 0) {
                    this.setState({ showProductSelectError: true });
                    validate.isValid = false;
                }
            } else {
                this.setState({ showOrderAgeLimitError: false });

                if (orderDate > maxDate) validate = await this.validateOrderDetails({ ...returnWizardDetails.payload.orderDetails, orderDate: "" });
                else {
                    updatedOrderDetails = { ...returnWizardDetails.payload.orderDetails, orderDate: orderDate }
                    this.setState(prevState => ({
                        returnWizardDetails: {
                            ...prevState.returnWizardDetails,
                            payload: {
                                ...prevState.returnWizardDetails.payload,
                                orderDetails: updatedOrderDetails
                            }
                        }
                    }));
                    validate = await this.validateOrderDetails(updatedOrderDetails);

                    const orderAgeLimitRetailer = {
                        retailerID: returnWizardDetails.payload.retailerDetails.id,
                        orderDate: orderDate.toDateString()
                    }

                    const validOrderAgeLimit = await this.fetchValidOrderLimit(orderAgeLimitRetailer);

                    if (!validOrderAgeLimit) {
                        this.setState({ showOrderAgeLimitError: true });
                        validate.isValid = false;
                    }
                }
            }
        }
        else if (activeStep === ActiveStep_ReturnReason) validate = await this.validateProductDetails(returnWizardDetails.payload.productDetails);
        else if (activeStep === ActiveStep_CollectionDate) validate = await this.validateReturnDetails(returnWizardDetails.payload.returnDetails);

        // Drop-off point route
        else if (activeStep === ActiveStep_LocationLookUp) {
            validate = await this.validateMap();
        }

        if ((validate !== null && validate.isValid)) {
            // Home collection route
            if (activeStep === ActiveStep_SelectRetailer) {
                await this.saveStep(Endpoints.RETURN.POST.SAVE_RETAILER, returnWizardDetails.payload.retailerDetails);

                if(isHomeCollection || isDropOff) await this.saveStep(Endpoints.RETURN.POST.SAVE_COLLECTION, { id: this.state.selectedOption }, false);
            }
            else if (activeStep === ActiveStep_ReturnMethod) await this.saveStep(Endpoints.RETURN.POST.SAVE_COLLECTION, { id: this.state.selectedOption });
            else if (activeStep === ActiveStep_CollectionAddress) await this.saveStep(Endpoints.RETURN.POST.SAVE_ADDRESS, returnWizardDetails.payload.addressDetails);
            else if (activeStep === ActiveStep_OrderDetails) {
                if (hasValidSelazarIntegration) {
                    if (showProducts) {
                        if (returnWizardDetails.payload.selazarOrderDetails.products.length > 0) {
                            await this.saveStep(Endpoints.RETURN.POST.SAVE_SELAZAR_ORDER, returnWizardDetails.payload.selazarOrderDetails);
                            this.setState({ showProductSelectError: false });
                        } else this.setState({ showProductSelectError: true });
                    }
                } else {
                    const orderDateMoment = moment.utc(updatedOrderDetails.orderDate).tz("Europe/London");
                    var orderDetails = {
                        emailAddress: returnWizardDetails.payload.orderDetails.emailAddress,
                        orderID: returnWizardDetails.payload.orderDetails.orderID,
                        orderDate: orderDateMoment.format().split("T")[0]
                    };
                    await this.saveStep(Endpoints.RETURN.POST.SAVE_ORDER, orderDetails);
                }
            }
            else if (activeStep === ActiveStep_ReturnReason) await this.saveStep(Endpoints.RETURN.POST.SAVE_PRODUCT, returnWizardDetails.payload.productDetails);
            else if (activeStep === ActiveStep_CollectionDate) {
                const collectionDateMoment = moment.utc(returnWizardDetails.payload.returnDetails.collectionDate).tz("Europe/London");
                const returnDetails = { collectionDate: collectionDateMoment.format().split("T")[0] };
                await this.saveStep(Endpoints.RETURN.POST.SAVE_RETURN, returnDetails);
            }

            // Drop-off route
            else if (activeStep === ActiveStep_LocationLookUp) this.nextStep();
        }
        this.setState({ loading: false });
    }

    nextStep = () => {
        var currentindex = this.state.steps.indexOf(this.state.activeStep);

        this.setState(state => ({
            activeStep: state.steps[currentindex + 1],
            error: false,
            errorMessages: []
        }));

        if (this.state.activeStep === ActiveStep_ReturnInfo) this.retrieveSummary();
        if (this.state.activeStep === ActiveStep_ReturnSummary) this.retrieveReturn();
    }

    handleBack = () => {
        const { activeStep, showProducts } = this.state;
        var currentindex = this.state.steps.indexOf(this.state.activeStep);

        // Home collection route
        if (activeStep === ActiveStep_ReturnMethod) this.setState({ selectedOption: null, isNextDisabled: false });
        if (activeStep === ActiveStep_CollectionAddress) this.setState({ selectedOption: null, isHomeCollection: false, isDropOff: false, isNextDisabled: false });

        if (activeStep === ActiveStep_OrderDetails && showProducts) {
            this.setState(prevState => ({
                orderProductDetails: {},
                selazarOrderDetails: {},
                showProducts: false,
                returnWizardDetails: {
                    ...prevState.returnWizardDetails,
                    payload: {
                        ...prevState.returnWizardDetails.payload,
                        selazarOrderDetails: { ...prevState.returnWizardDetails.payload.selazarOrderDetails, products: [] }
                    }
                }
            }));
        }

        // Drop-off route
        if(activeStep === ActiveStep_LocationLookUp) this.setState({ selectedOption: null, isHomeCollection: false, isDropOff: false, isNextDisabled: false });

        if(activeStep === ActiveStep_OrderDetails) {
            this.setState(prevState => ({
                orderProductDetails: {},
                selazarOrderDetails: {},
                showProducts: false,
                returnWizardDetails: {
                    ...prevState.returnWizardDetails,
                    payload: {
                        ...prevState.returnWizardDetails.payload,
                        selazarOrderDetails: { ...prevState.returnWizardDetails.payload.selazarOrderDetails, products: [] }
                    }
                }
            }));
        }
    
        this.setState(state => ({
            activeStep: state.steps[currentindex - 1],
            error: false,
            errorMessages: []
        }));
    };

    // Validation
    setValidationState = async (validation, text) => {
        if (validation.isValid) {
            validation.isValid = true;
            this.setState({ errorMessages: [] });
        }
        else {
            validation.isValid = false;
            text && this.setState({ errorMessages: [text] });
        }

        return validation;
    }

    validateAddressDetails = async (addressDetails) => {
        if (addressDetails !== null && addressDetails.id !== "" && addressDetails.id !== undefined) {
            return { isValid: true };
        }
        else {
            this.setState({ errorMessages: ["You must select an address to continue"] });
            return { isValid: false };
        }
    }

    validateRetailerDetails = async (retailerDetails) => {

        let retailerValidation = this.retailerValidator.validate(retailerDetails);
        this.setState({ retailerValidation: retailerValidation });

        return await this.setValidationState(retailerValidation, "You must select a retailer");
    }

    validateOrderDetails = async (orderDetails) => {

        let orderValidation = this.orderValidator.validate(orderDetails);
        this.setState({ orderDetailsValidation: orderValidation });

        return await this.setValidationState(orderValidation);
    }

    validateSelazarOrderDetails = async (selazarOrderDetails) => {
        const selazarOrderValidation = this.selazarOrderValidator.validate(selazarOrderDetails)
        this.setState({ selazarOrderValidation: selazarOrderValidation });
        return await this.setValidationState(selazarOrderValidation);
    }

    validateProductDetails = async (productDetails) => {
        if (productDetails.returnReason === REASON_OTHER) {
            let productValidation = this.productValidator.validate(productDetails);
            let productReasonAdditionalInformationValidation = this.productReasonAdditionalInformationValidator.validate(productDetails);

            this.setState({ productDetailsValidation: productValidation, productReasonAdditionalInformationValidation });

            return productValidation.isValid ? this.setValidationState(productReasonAdditionalInformationValidation) : this.setValidationState(productValidation);
        } else {
            let productValidation = this.productValidator.validate(productDetails);
            let productReasonAdditionalInformationValidation = this.productReasonAdditionalInformationValidator.validate(productDetails);
            this.setState({ productDetailsValidation: productValidation, productReasonAdditionalInformationValidation });

            return await (productValidation.isValid ? this.setValidationState(productReasonAdditionalInformationValidation) : this.setValidationState(productValidation))
        }
    }

    validateReturnDetails = async (returnDetails) => {

        const formattedReturnDetails = { collectionDate: returnDetails.collectionDate === "" ? new Date(0).toDateString() : new Date(returnDetails.collectionDate).toDateString() }
        let returnValidation = this.returnValidator.validate(formattedReturnDetails);
        this.setState({ returnDetailsValidation: returnValidation });

        return await this.setValidationState(returnValidation);
    }

    validateRadioButtons = async (selectedOption) => {
        let buttonValidation = this.radioButtonValidation;
        selectedOption === null ?  buttonValidation.isValid=false : buttonValidation.isValid=true;
        this.setState({ buttonValidation: buttonValidation });

        return await this.setValidationState(buttonValidation, "You must select a return method");
    }

    validateMap = async () => {
        let mapValidation = this.mapValidation;
        mapValidation.isValid=true;
        this.setState({ mapValidation: mapValidation });

        return await this.setValidationState(mapValidation, "You must select a return method");
    }

    // Input handlers
    handleRetailerSelection = (e) => {
        const { id, name } = e.target;

        let checkboxes = [...document.getElementsByClassName("retailerCheckBox")].filter(e => e.id !== id);
        for (var i = 0; i < checkboxes.length; i++) {
            checkboxes[i].checked = false;
        }

        this.setState(prevState => ({
            isNextDisabled: false,
            returnWizardDetails: {
                ...prevState.returnWizardDetails,
                payload: {
                    ...prevState.returnWizardDetails.payload,
                    retailerDetails: {
                        ...prevState.returnWizardDetails.payload.retailerDetails,
                        id: id,
                        name: name
                    }
                }
            }
        }), () => {this.getRetailerServices()});
    }

    handleAddressSelection = (id, address) => {
        const addressLines = address.line2 ? `${address.line1}, ${address.line2}, ` : `${address.line1}, `;
        const summary = `${address.name}, ${addressLines} ${address.city}, ${address.county}, ${address.postcode}`

        this.setState(prevState => ({
            isNextDisabled: false,
            selectedAddressFull: address,
            returnWizardDetails: {
                ...prevState.returnWizardDetails,
                payload: {
                    ...prevState.returnWizardDetails.payload,
                    addressDetails: {
                        ...prevState.returnWizardDetails.addressDetails,
                        id: id,
                        summary: summary
                    }
                }
            }
        }));
    }

    handleOrderDetailsInputChange = (e) => {
        const { name, value } = e.target;

        this.setState(prevState => ({
            returnWizardDetails: {
                ...prevState.returnWizardDetails,
                payload: {
                    ...prevState.returnWizardDetails.payload,
                    orderDetails: {
                        ...prevState.returnWizardDetails.payload.orderDetails,
                        [name]: value
                    }
                }
            }
        }));
    }

    handleSelazarOrderDetailsInputChange = (name, value) =>
        this.setState(prevState => ({
            returnWizardDetails: {
                ...prevState.returnWizardDetails,
                payload: {
                    ...prevState.returnWizardDetails.payload,
                    selazarOrderDetails: { ...prevState.returnWizardDetails.payload.selazarOrderDetails, [name]: value }
                }
            }
        }));

    handleProductSelect = (index) => {
        const { returnWizardDetails, orderProductDetails } = this.state;
        const selectedProducts = returnWizardDetails.payload.selazarOrderDetails.products;

        let updatedSelectedProducts = [];
        const addSelectedProduct = selectedProducts.find(p => p.index === index) === undefined;
        if (addSelectedProduct) {
            selectedProducts.push(orderProductDetails.products.find(p => p.index === index));
            updatedSelectedProducts = selectedProducts;
        } else {
            updatedSelectedProducts = selectedProducts.filter(sp => sp.index !== index);
        }

        this.setState(prevState => ({
            returnWizardDetails: {
                ...prevState.returnWizardDetails,
                payload: {
                    ...prevState.returnWizardDetails.payload,
                    selazarOrderDetails: { ...prevState.returnWizardDetails.payload.selazarOrderDetails, products: updatedSelectedProducts }
                }
            }
        }));
    }

    handleProductDetailsInputChange = (e) => {
        const { name, value } = e.target;

        this.setState(prevState => ({
            returnWizardDetails: {
                ...prevState.returnWizardDetails,
                payload: {
                    ...prevState.returnWizardDetails.payload,
                    productDetails: {
                        ...prevState.returnWizardDetails.payload.productDetails,
                        [name]: value
                    }
                }
            }
        }));
    }

    handleParcelConfirmationInputChange = () => {
        this.setState(prevState => ({
            returnWizardDetails: {
                ...prevState.returnWizardDetails,
                payload: {
                    ...prevState.returnWizardDetails.payload,
                    parcelConfirmationDetails: {
                        confirmation: !prevState.returnWizardDetails.payload.parcelConfirmationDetails.confirmation
                    }
                }
            }
        }));
    }

    handleReturnDetailsInputChange = (date) => {
        this.setState(prevState => ({
            returnWizardDetails: {
                ...prevState.returnWizardDetails,
                payload: {
                    ...prevState.returnWizardDetails.payload,
                    returnDetails: {
                        collectionDate: date
                    }
                }
            }
        }));
    }

    handleOrderDetailsOrderDateInputChange = (date) => this.setState({ orderDate: date });

    //handle radio-button change
    handleRadioButtonChange = e => {
        const selectedValue = parseInt(e.target.value);

        // Drop-off point
        if(selectedValue === 1) {           
            this.setState({ 
                selectedOption: selectedValue,
                steps: [ActiveStep_SelectRetailer, ActiveStep_ReturnMethod, ActiveStep_LocationLookUp, ActiveStep_OrderDetails, ActiveStep_ReturnReason, ActiveStep_ReturnInfo, ActiveStep_ReturnSummary ],
                isNextDisabled: false
            });
        }
        else {
            this.setState({ 
                selectedOption: selectedValue,
                steps: [ActiveStep_SelectRetailer, ActiveStep_ReturnMethod, ActiveStep_CollectionAddress, ActiveStep_OrderDetails, ActiveStep_ReturnReason, ActiveStep_CollectionDate, ActiveStep_ReturnInfo, ActiveStep_ReturnSummary],
                isNextDisabled: false
            });
        }
    };

    handleStepFormat = steps => {
        var arr = [];
        for (var i = 0; i < steps.length - 1; i++) arr.push("");
        return arr;
    }

    handleCollectPlusDropOffPostCodeChange = (e) => {
        const { value } = e.target;
        this.setState({ collectPlusDropOffPostCodeText : value });
    }

    onCollectPlusDropOffPostCodeSearch = () => {
        const { collectPlusDropOffPostCodeText } = this.state;
        collectPlusDropOffPostCodeText !== "" && this.fetchCollectPlusDropOffPoints(collectPlusDropOffPostCodeText);
    }

    render() {
        const { steps, activeStep, permitted, permittedErrors, summary, retailerValidation, orderDetailsValidation, selazarOrderValidation, productDetailsValidation, 
            productReasonAdditionalInformationValidation, returnDetailsValidation, parcelConfirmationValidation, returnWizardDetails, 
            userAddresses, errorMessages, orderDate, hasValidSelazarIntegration, showOrderAgeLimitError, orderProductDetails, showProducts, showProductSelectError, 
            showProductsOrderAgeError, showProductsError, showCollectPlusMap, collectPlusDropOffSearchError, loading, selectedOption, collectPlusDropOffPoints, 
            collectPlusSearchLocation, collectPlusDropOffPostCodeText, createdReturnDetails, showReturnMethodErrorModal, returnMethodError, selectedAddressFull,
            showSubmitErrorModal, bankHolidays } = this.state;
            
        const addressDetails = returnWizardDetails.payload.addressDetails;
        const retailerDetails = returnWizardDetails.payload.retailerDetails;
        const orderDetails = returnWizardDetails.payload.orderDetails;
        const selazarOrderDetails = returnWizardDetails.payload.selazarOrderDetails;
        const productDetails = returnWizardDetails.payload.productDetails;
        const returnDetails = returnWizardDetails.payload.returnDetails;
        const parcelConfirmationDetails = returnWizardDetails.payload.parcelConfirmationDetails;

        return (<Fragment>
            {loading
                ? <LoadingBar />
                : (permitted.lowScore === false && permitted.tooManyReturns === false)
                    ? <div className="main-content">
                        <div className="main-container">
                            <h3>Create Return</h3>
                            <Stepper steps={this.handleStepFormat(steps)} activeStep={steps.indexOf(activeStep)} />
                            <div className="pt-3">
                                {errorMessages.length > 0 && <ErrorAlert messages={errorMessages} />}
                                {activeStep === ActiveStep_SelectRetailer &&
                                     <Fragment>
                                        <CreateReturnRetailerDetails retailerDetails={retailerDetails} retailerValidation={retailerValidation} handleRetailerSelection={this.handleRetailerSelection} />
                                        <StepperButtons onClickCancel={this.handleShow} cancelText={CANCEL_TEXT} cancelLink={CANCEL_LINK} isDisabled={this.state.isNextDisabled} handleNext={this.handleNext} showNext={true} />
                                        {/* if just home collection chosen call the homeCollection component straight*/}
                                    </Fragment>
                                }

                                {/* Home collection route */}
                                {activeStep === ActiveStep_ReturnMethod &&
                                    <Fragment>
                                        <h4 className="mt-2 mb-3">Return Method</h4>
                                        <h6 className="mt-2 mb-3">How you would like to return your parcel?</h6>
                                        <FormGroup>
                                            <FormGroup className="custom-control custom-radio mb-0">
                                                <FormControl className="custom-control-input" type="radio" id="radio-homeCollection" name="service" value={0} onChange={this.handleRadioButtonChange} />
                                                <FormLabel className="custom-control-label font-weight-normal" htmlFor="radio-homeCollection">Home Collection</FormLabel>
                                            </FormGroup>
                                            <FormGroup className="custom-control custom-radio mb-0">
                                                <FormControl className="custom-control-input" type="radio" id="radio-dropOff" name="service" value={1} onChange={this.handleRadioButtonChange} />
                                                <FormLabel className="custom-control-label font-weight-normal" htmlFor="radio-dropOff">Drop-off Point {selectedOption === 1 && weightAlert}</FormLabel>
                                            </FormGroup>
                                        </FormGroup>
                                        <StepperButtons onClickCancel={this.handleShow} cancelText={CANCEL_TEXT} showBack={true} handleBack={this.handleBack} cancelLink={CANCEL_LINK} isDisabled={this.state.isNextDisabled} handleNext={this.handleNext} showNext={true} />
                                    </Fragment>
                                }

                                {activeStep === ActiveStep_CollectionAddress &&
                                    <Fragment>
                                        <CreateReturnAddress fetchAddresses={this.fetchAddresses} userAddresses={userAddresses} selectedAddressId={addressDetails.id} handleAddressSelection={this.handleAddressSelection} />
                                        <StepperButtons onClickCancel={this.handleShow} cancelText={CANCEL_TEXT} showBack={true} handleBack={this.handleBack} cancelLink={CANCEL_LINK} isDisabled={this.state.isNextDisabled} handleNext={this.handleNext} showNext={true} />
                                    </Fragment>
                                }

                                {activeStep === ActiveStep_OrderDetails &&                               
                                    <Fragment>
                                        {showOrderAgeLimitError && <ErrorAlert messages="The return is not within that retailer's return time window." />}
                                                {hasValidSelazarIntegration
                                                    ? <ReturnOrderSelazarIntDetails
                                                        orderProductDetails={orderProductDetails}
                                                        selazarOrderDetails={selazarOrderDetails}
                                                        selazarOrderValidation={selazarOrderValidation}
                                                        handleSelazarOrderDetailsInputChange={this.handleSelazarOrderDetailsInputChange}
                                                        showProducts={showProducts}
                                                        handleProductSelect={this.handleProductSelect}
                                                        showProductSelectError={showProductSelectError}
                                                        showProductsOrderAgeError={showProductsOrderAgeError}
                                                        showProductsError={showProductsError}
                                                    />
                                                    : <ReturnOrderDetails
                                                        handleOrderDetailsOrderDateInputChange={this.handleOrderDetailsOrderDateInputChange}
                                                        orderDetails={orderDetails}
                                                        orderDate={orderDate}
                                                        orderValidation={orderDetailsValidation}
                                                        handleOrderDetailsInputChange={this.handleOrderDetailsInputChange}
                                                    />
                                                }
                                        <StepperButtons onClickCancel={this.handleShow} cancelText={CANCEL_TEXT} showBack={true} handleBack={this.handleBack} cancelLink={CANCEL_LINK} isDisabled={this.state.isNextDisabled} handleNext={this.handleNext} showNext={true} />
                                    </Fragment>
                                }
                                {activeStep === ActiveStep_ReturnReason &&
                                    <Fragment>
                                        <CreateReturnProductDetails productDetails={productDetails} productValidation={productDetailsValidation} handleProductDetailsInputChange={this.handleProductDetailsInputChange} productReasonAdditionalInformationValidation={productReasonAdditionalInformationValidation}/>
                                        <StepperButtons onClickCancel={this.handleShow} cancelText={CANCEL_TEXT} showBack={true} handleBack={this.handleBack} cancelLink={CANCEL_LINK} handleNext={this.handleNext} showNext={true} />
                                    </Fragment>
                                }
                                {activeStep === ActiveStep_CollectionDate &&
                                    <Fragment>
                                        <CreateReturnDetails returnDetails={returnDetails} bankHolidays={bankHolidays} returnValidation={returnDetailsValidation} handleReturnDetailsInputChange={this.handleReturnDetailsInputChange} />
                                        <StepperButtons onClickCancel={this.handleShow} cancelText={CANCEL_TEXT} showBack={true} handleBack={this.handleBack} cancelLink={CANCEL_LINK} handleNext={this.handleNext} showNext={true} />
                                    </Fragment>
                                }
                                {activeStep === ActiveStep_ReturnInfo &&
                                    <Fragment>
                                        <CreateReturnSummary
                                            summary={summary}
                                            parcelConfirmationDetails={parcelConfirmationDetails}
                                            handleParcelConfirmationInputChange={this.handleParcelConfirmationInputChange}
                                            parcelConfirmationValidation={parcelConfirmationValidation}
                                            isHomeCollection={selectedOption === 0}
                                            selectedAddressFull={selectedAddressFull}
                                            hasValidSelazarIntegration={hasValidSelazarIntegration} />
                                        <StepperButtons
                                            nextButtonText="Submit Return"
                                            onClickCancel={this.handleShow}
                                            cancelText={CANCEL_TEXT}
                                            showBack={true}
                                            handleBack={this.handleBack}
                                            cancelLink={CANCEL_LINK}
                                            handleNext={this.handleSubmit}
                                            showNext={true} />
                                    </Fragment>
                                }
                                {activeStep === ActiveStep_ReturnSummary &&
                                    <Fragment>
                                        <CreateReturnConfirmation
                                            createdReturnDetails={createdReturnDetails}
                                            selectedAddressFull={selectedAddressFull}
                                            showProducts={showProducts}
                                            showCreatedAlert={true}
                                            hasValidSelazarIntegration={hasValidSelazarIntegration}
                                            isCreateReturnWizard={true} />
                                        <Row>
                                            <Col xs={12} sm={12} md={{ span: 4, offset: 8 }}>
                                                <Button variant="primary" block onClick={() => this.props.history.push('/return')}>Continue to Returns</Button>
                                            </Col>
                                        </Row>
                                    </Fragment>
                                }

                                {/* Drop-off point route */}
                                {activeStep === ActiveStep_LocationLookUp &&
                                    <Fragment>
                                        <CreateReturnLocationLookup showCollectPlusMap={showCollectPlusMap} collectPlusDropOffSearchError={collectPlusDropOffSearchError} collectPlusDropOffPoints={collectPlusDropOffPoints} collectPlusSearchLocation={collectPlusSearchLocation} collectPlusDropOffPostCodeText={collectPlusDropOffPostCodeText} handleCollectPlusDropOffPostCodeChange={this.handleCollectPlusDropOffPostCodeChange} onCollectPlusDropOffPostCodeSearch={this.onCollectPlusDropOffPostCodeSearch} />
                                        <StepperButtons onClickCancel={this.handleShow} cancelText={CANCEL_TEXT} showBack={true} handleBack={this.handleBack} cancelLink={CANCEL_LINK} isDisabled={this.state.isNextDisabled} handleNext={this.handleNext} showNext={true} />
                                    </Fragment>
                                }
                            </div>
                        </div>
                        <Confirm handleConfirmAction={this.handleCancelAccount} title={CANCEL_TEXT} variant="outline-danger" block="true" buttonText={CANCEL_TEXT} text="Are you sure that you want to cancel creating this return? We will not save your progress." show={this.state.show} handleClose={this.handleClose} handleCancelAccount={this.handleCancelAccount} handleCloseModal={this.handleCloseModal} closeLink={true} linkText="Return to create return" />
                        <Confirm handleConfirmAction={this.handleReturnMethodModal} block="true" buttonText="OK" text={returnMethodError} show={showReturnMethodErrorModal} showHeader={false} closeLink={true} />
                    </div>
                    : permittedErrors.length > 0
                        ? <ErrorAlert messages={permittedErrors} />
                        : <ReturnPermissionDenied handleOkButton={this.handleOkButton} tooManyReturns={permitted.tooManyReturns} robinScore={permitted.robinScore} />
            }
            <Confirm
                handleConfirmAction={this.handleCloseSubmitErrorModal}
                title="Return Unsuccessful"
                block="true"
                buttonText="OK"
                text="Unfortunately we were unable to create your return. Please try creating a new one."
                show={showSubmitErrorModal}
                showCloseButton={false} />
        </Fragment>);

    }
}

export default CreateReturnWizard;