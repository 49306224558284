import React, { Component } from 'react';
import { Form, Button } from 'react-bootstrap';
import RRLogo from '../../common/RRLogo'
import Endpoints from '../../common/Endpoints';
import { POST_ASYNC } from '../../../Consumer';
import FormValidator from '../../common/FormValidator';
import Confirm from '../../common/modals/Confirm'
import Mobile from '../../common/Mobile';
import { PHONE_UTIL } from '../shared/Snippets';
import { setCaretPosition, isEmpty } from '../../../Utilities';
import './AccountRegister.scss';

export class ChangeMobileNumber extends Component {

    constructor(props) {
        super(props);

        this.validator = new FormValidator([
            {
                field: 'phoneNumber',
                method: 'isEmpty',
                validWhen: false,
                message: 'This is not a valid mobile number, try again.'
            }
        ]);

        this.state = {
            form: {
                phoneNumber: '',
            },
            userId: this.props.location.state !== undefined ? this.props.location.state.userId : undefined,
            validation: this.validator.valid(),
            warning: false,
            errorMessages: [],
            heading: '',
            submitted: false,
            show: false,
            loading: false,
            confirmationValidation: true,
        };
    }

    validate = (form) => {
        let validation = this.validator.validate(form);

        if (!isEmpty(form.phoneNumber)) {
            try {
                const phone = PHONE_UTIL.parse(form.phoneNumber, form.countryCode);
                console.log(PHONE_UTIL.getNumberType(phone));
                if (!PHONE_UTIL.isValidNumber(phone)) {
                    validation.isValid = false;
                    validation.phoneNumber.isInvalid = true;
                    validation.phoneNumber.message = "This is not a valid mobile number, try again.";
                }
                else if(PHONE_UTIL.getNumberType(phone) !== 1){
                    console.log('in the else part');
                    validation.isValid = false;
                    validation.phoneNumber.isInvalid = true;
                    validation.phoneNumber.message = "This is not a valid mobile number, try again.";
                }
            } catch (e) {
                console.log(e);
                validation.isValid = false;
                validation.phoneNumber.isInvalid = true;
                validation.phoneNumber.message = "This is not a valid mobile number, try again.";
            }
        }
        return validation;
    }

    handlePhoneNumberChange = (...args) => {
        setCaretPosition(args[3].length);

        this.setState(prevState => ({
            ...prevState,
            form: {
                ...prevState.form,
                ...{
                    phoneNumber: args[3],
                    countryCode: args[2].iso2
                }
            }
        }));
    }

    handleCancelAccount = async (e) => {
        e.preventDefault();

        const vm = {
            UserID: this.state.userId,
        };

        await POST_ASYNC(Endpoints.USER.POST.CANCELREGISTRATION, vm)
            .then(response => response.json())
            .then((result) => {
                if (result.error) result.data !== null ? this.setState({ errorMessages: result.data }) : this.setState({ errorMessages: result.message });
                else this.props.history.push("/register/cancelled");
            }).catch(() => this.setState({ errorMessages: ["There has been an issue during cancellation."] }));
    }

    handleShow = (e) => {
        e.preventDefault();
        this.setState({ show: true });
    }

    handleClose = () => {
        this.setState({ show: false });
    }

    handleCloseModal = (e) => {
        e.preventDefault();
        this.setState({ show: false });
    }

    handleSubmit = async (e) => {
        e.preventDefault();
        this.setState({ loading: true });

        const { form } = this.state;
        const validation = this.validate(form);
        this.setState({ validation: validation, submitted: true });

        if (validation.isValid) {
            const vm = {
                UserID: this.state.userId,
                MobileNumber: this.state.form.phoneNumber
            };
            
            await POST_ASYNC(Endpoints.USER.POST.REGISTRATION_RESENDVERIFICATIONCODE, vm)
                .then(response => response.json())
                .then((result) => {
                    if (result.error) {
                        if(result.data !== null) this.setState({ error: false, errorMessages: result.data, loading: false })
                        else this.setState({ error: true, errorMessages: result.message, loading: false });
                    }
                    else this.props.history.push({ pathname: "/register/accountverification", state: { userId: result.data.id, phoneNumber: result.data.phoneNumber } });
                })
                .catch(() => this.props.history.push({ pathname: "/register/issue" , errorMessages: ["There has been an issue during submit."] }));
        } else this.setState({ loading: false });
    }
    
    render() {
        const { showLogo = true, form, validation } = this.state;

        return (
            <> 
                <div className="mobileNumber-center">
                    <div className='mobileNumber-container'>
                        <Form onSubmit={this.handleSubmit}>
                            <div className="center-block mb-5">
                                {showLogo && <RRLogo />}
                                
                                <h6 className='mobile-h6'>Edit mobile number</h6>
                                <Mobile value={form.phoneNumber} invalidPhoneNumber={validation.phoneNumber.isInvalid} handlePhoneNumberChange={this.handlePhoneNumberChange} validationMessage={validation.phoneNumber.message} />
                                <Button variant="primary" block type="submit" value="Submit">Save and resend verification code</Button>

                                <p className="mt-4">If you no longer want to register with Return Robin you can <Button variant="link" className="link-button-text" onClick={this.handleShow}>cancel account creation.</Button></p>
                                <Confirm title="Cancel Account Creation" variant="outline-danger" linkText="Return to edit mobile number" block="true" buttonText="Cancel Account Creation" text="Are you sure that you no longer want to set up a Return Robin account?" show={this.state.show} handleClose={this.handleClose} handleConfirmAction={this.handleCancelAccount} closeLink={true} />
                            </div>                     
                        </Form>
                    </div>
                </div>         
            </>   
        )
    }

}