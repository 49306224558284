import React, { Component, Fragment } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import Confirm from '../components/common/modals/Confirm';

import './Header.scss';

class Header extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showLogoutModal: false
        };
    }

    logout = () => {
        this.setState({ showLogoutModal: false });
        this.props.onLogout(this.props.isRetailer);
    }

    handleLogoutClose = () => this.setState({ showLogoutModal: false });

    handleLogoutOpen = () => this.setState({ showLogoutModal: true });

    addressCompleted = () => {
        const user = JSON.parse(localStorage.getItem('user'));
        return user !== null ? user.setupComplete : false;
    }

    render() {
        const { showLogoutModal } = this.state;
        const { isRetailer } = this.props;
        const addressCompleted = this.addressCompleted();

        return (
            <Fragment>
                <Navbar collapseOnSelect expand="lg" className="header shadow-sm fixed-top bg-navbar">
                    <Link to="/">
                        <img
                            src="Images/logo_light_icon.svg"
                            width="30"
                            height="30"
                            className="d-sm-block d-md-block d-lg-none d-xl-none"
                            alt="Return Robin"
                        />
                    </Link>
                    <Link to="/">
                        <img src="Images/logo_light_horizontal.svg"
                            className="d-none d-sm-none d-md-none d-lg-block d-xl-block"
                            height="30"
                            alt="Return Robin" />
                    </Link>
                    <Nav className="ml-auto">
                        {isRetailer ?
                            <Fragment>
                                <Nav.Link href="https://support.selazar.com/return-robin" className="nav-faq" target="_blank">FAQ</Nav.Link>
                            </Fragment>
                            : <Fragment>
                                <Nav.Link href="https://support.returnrobin.com/" className="nav-faq" target="_blank">FAQ</Nav.Link>
                            </Fragment>}
                    </Nav>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="ml-auto">
                            {isRetailer ?
                                addressCompleted
                                    ? <Fragment>
                                        <Nav.Link as={Link} eventKey="1" className="nav-link mr-2" to='/retailer/return'>Returns</Nav.Link>
                                        <Nav.Link as={Link} eventKey="2" className="nav-link mr-2" to='/retailer/invoice'>Invoicing</Nav.Link>
                                        <Nav.Link as={Link} eventKey="3" className="nav-link mr-2" to='/retailer/settings'>Settings</Nav.Link>
                                        <Nav.Link eventKey="4" onClick={this.handleLogoutOpen}>Sign Out <FontAwesomeIcon className="ml-1" icon={faSignOutAlt} /></Nav.Link>
                                    </Fragment>
                                    : <Nav.Link eventKey="5" onClick={this.handleLogoutOpen}>Sign Out <FontAwesomeIcon className="nav-first ml-1" icon={faSignOutAlt} /></Nav.Link>
                                : <Fragment>
                                    {addressCompleted && <Nav.Link as={Link} eventKey="1" className="nav-link nav-first mr-2" to='/return/create'>Create a Return</Nav.Link>}
                                    {addressCompleted && <Nav.Link as={Link} eventKey="2" className="nav-link mr-2" to='/return'>Returns</Nav.Link>}
                                    {addressCompleted && <Nav.Link as={Link} eventKey="3" className="nav-link mr-2" to='/address/youraddresses'>Address Book</Nav.Link>}
                                    {addressCompleted && <Nav.Link as={Link} eventKey="4" className="nav-link mr-2" to='/profile'>Your Profile</Nav.Link>}
                                    <Nav.Link eventKey="5" onClick={this.handleLogoutOpen}>Sign Out <FontAwesomeIcon className={addressCompleted ? "ml-1" : "nav-first ml-1"} icon={faSignOutAlt} /></Nav.Link>
                                </Fragment>}
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
                <Confirm title={"Log Out"} text={"Are you sure you want to log out?"} buttonText={"Log Out"} handleClose={this.handleLogoutClose} closeLink={true} linkText={"Stay Logged In"} handleConfirmAction={this.logout} show={showLogoutModal} large={false} />
            </Fragment>
        );
    }
}

export default Header;