import React, { Component } from 'react';
import { Form, FormGroup, FormLabel, FormControl, Button } from 'react-bootstrap';
import RRLogo from '../../common/RRLogo'
import Endpoints from '../../common/Endpoints';
import { GET_ASYNC, POST_ASYNC } from '../../../Consumer';
import FormValidator from '../../common/FormValidator';
import Confirm from '../../common/modals/Confirm'
import { Redirect } from 'react-router-dom';
import { ErrorAlert } from '../../common/Alerts';
import LoadingBar from '../../common/LoadingBar';

export class AccountVerification extends Component {

    constructor(props) {

        super(props);

        this.validator = new FormValidator([
            {
                field: 'code',
                method: 'isEmpty',
                validWhen: false,
                message: 'Please enter the 6-digit code'
            }
        ]);

        this.state = {
            userId: this.props.location.state !== undefined ? this.props.location.state.userId : undefined,
            phoneNumber: this.props.location.state !== undefined ? this.props.location.state.phoneNumber : undefined,
            form: {
                userId: '',
                code: '',
            },
            validation: this.validator.valid(),
            error: false,
            errorMessages: [],
            show: false,
            verificationText: 'Resend verification code',
            loading: false
        }

    }

    validate = (form) => {
        let validation = this.validator.validate(form);
        return validation;
    }

    handleSubmit = async (e) => {
        e.preventDefault();
        this.setState({ loading: true });

        const { form } = this.state;
        const validation = this.validate(form);

        this.setState({ validation: validation });
        this.submitted = true;
        form.userId = this.state.userId;

        if (validation.isValid) 
        {
            await POST_ASYNC(Endpoints.USER.POST.REGISTRATION_MOBILE_NUMBER_CODE_VERIFICATION, form)
                .then(response => response.json())
                .then((result) => {
                    if (result.error) {
                        if (result.data !== null) this.setState({ error: false, errorMessages: result.data, loading: false });
                        else this.setState({ error: true, errorMessages: result.message, loading: false });
                    }
                    else this.props.history.push("/register/complete");
                }).catch(() => this.setState({ errorMessages: ["There has been an issue during submission."], loading: false }));
        }
    }

    handleResendCode = async (e) => {
        e.preventDefault();
        this.setState({ loading: true });

        await GET_ASYNC(Endpoints.USER.GET.RESEND_MOBILE_VERIFICATIONCODE + '?userId=' + this.state.userId)
            .then(response => response.json())
            .then((result) => {
                if (result.error) {
                    if (result.data !== null) this.setState({ error: false, errorMessages: result.data, loading: false });
                    else this.setState({ error: true, errorMessages: result.message, loading: false });
                }
                else this.setState({ error: false, errorMessages: result.data.message, verificationText: 'New verification code sent', form: { code: '' }, loading: false });

            }).catch(() => this.setState({ errorMessages: ["There has been an issue resending the code."], loading: false }));
    }

    handleCancelAccount = async (e) => {
        e.preventDefault();

        const vm = {
            UserID: this.state.userId,
        };

        await POST_ASYNC(Endpoints.USER.POST.CANCELREGISTRATION, vm)
            .then(response => response.json())
            .then((result) => {
                if (result.error) result.data !== null ? this.setState({ errorMessages: result.data }) : this.setState({ errorMessages: result.message });
                else this.props.history.push("/register/cancelled");
            }).catch(() => this.setState({ errorMessages: ["There has been an issue during cancellation."] }));
    }

    handleShow = (e) => {
        e.preventDefault();
        this.setState({ show: true });
    }

    handleClose = () => {
        this.setState({ show: false });
    }

    handleCloseModal = (e) => {
        e.preventDefault();
        this.setState({ show: false });
    }

    handleInputChange = (e) => {
        let { name, value } = e.target;

        if (e.target.getAttribute('parent')) {
            let parent = e.target.getAttribute('parent');
            let parentValue = this.state.form[parent];
            parentValue[name] = value;
            name = parent;
            value = parentValue;
        }

        this.setState(prevState => ({
            ...prevState,
            form: {
                ...prevState.form,
                ...{ [name]: value }
            }
        }));
    }

    handleChangeMobileNumber = (e) => {
        e.preventDefault();
        this.setState({ changeMobileNumber: true })

        this.props.history.push({ pathname: "/register/changemobilenumber", state: { userId: this.state.userId, phoneNumber: this.state.phoneNumber } });
    }

    renderRedirect = () => {
        if (this.state.userId === undefined) {
            return <Redirect to='/' />
        }
    }

    renderForm = () => {
        const { form, validation, errorMessages } = this.state

        return (
            <Form onSubmit={this.handleSubmit}>
                <FormGroup>
                    {errorMessages.length > 0 && <ErrorAlert messages={errorMessages} />}
                    <FormLabel htmlFor="verificationCodeField">Verification Code</FormLabel>
                    <FormControl type="text" className={validation.code.isInvalid ? "input-error" : undefined} value={form.code} name="code" onChange={this.handleInputChange} placeholder="Enter the 6-digit code" />
                    {validation.code.message.length > 0
                        ? <div className="errorSpan">
                            <span className="text-danger">{validation.code.message}</span>
                        </div>
                        : undefined}
                </FormGroup>
                <Button variant="primary" block type="submit" value="Submit">Verify Code</Button>
            </Form>);
    }

    render() {
        const { verificationText, loading } = this.state;;

        return (
            <div className="accountVerification-center">
                <div className="accountVerification-container">
                    {loading && <LoadingBar />}
                    <RRLogo />
                    <div className="center-block mb-5">
                        <h3>Account Verification</h3>
                        <p className="text">We have sent a message containing a 6-digit Verification code to {this.state.phoneNumber}. < br/>
                        Want to change your mobile number? Edit it <Button variant="link" className="link-button-text" onClick={this.handleChangeMobileNumber}>here.</Button></p>
             
                        {this.renderForm()}

                        <p className="mt-3 font-weight-bold small-margin">Having problems?</p>
                        <p>Resend a Verification code and try again. If you need further help contact <a href="mailto:hello@returnrobin.com">hello@returnrobin.com</a></p>
                        <Button variant="outline-primary" block onClick={this.handleResendCode}>{verificationText}</Button>
                        <p className="mt-4">If you no longer want to register with Return Robin you can <Button variant="link" className="link-button-text" onClick={this.handleShow}>cancel account creation.</Button></p>

                        <Confirm title="Cancel Account Creation" variant="outline-danger" linkText="Return to account verification" block="true" buttonText="Cancel Account Creation" text="Are you sure that you no longer want to set up a Return Robin account?" show={this.state.show} handleClose={this.handleClose} handleConfirmAction={this.handleCancelAccount} closeLink={true} />
                        {this.renderRedirect()}
                    </div>
                </div>
            </div>
        )
    }

}