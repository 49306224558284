import React from 'react';
import { Row, Col, Button, Image } from 'react-bootstrap';
import LoadingBar from '../common/LoadingBar';
import { ScoreDetails, ScoreInfo } from './shared/Snippets';
import Endpoints from '../common/Endpoints';
import { GET } from '../../Consumer';
import { ErrorAlert } from '../common/Alerts';
import './shared/Profile.scss';
import { Link } from 'react-router-dom';

const ContactDetails = ({ contactDetails, userID }) => (
    <section>
        <h4>Contact Details</h4>
        <p className="title">Email Address</p>
        <p>{contactDetails.email} <Link to={{ pathname: `profile/editemailaddress`, emailAddress: contactDetails.email, userID: userID }}> <Image src='Images/penToSquare.svg' /> </Link> </p>
        <p className="title">Mobile Number</p>
        <p>{contactDetails.mobile} <Link to={{ pathname: `profile/editmobilenumber`, mobileNumber: contactDetails.mobile, userID: userID }}> <Image src='Images/penToSquare.svg' /> </Link> </p>
    </section>
);

class Profile extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            error: "",
            profile: {},
            userID: JSON.parse(localStorage.getItem('user')).user.id.toString(),
        };
    }

    async componentDidMount() {
        await this.getProfile();
        this.setState({ loading: false });
    }

    getProfile = () => {
        return GET(Endpoints.SCORE.GET.PROFILE)
            .then(response => response.json())
            .then(result => {
                const profile = result.data ? result.data.profile : null;
                const error = profile ? "" : "We are currently unable to retrieve profile details.";
                this.setState({ profile: profile, error: error });
            });
    }

    render() {
        const { profile, error, loading, userID } = this.state;

        return (
            <React.Fragment>
                {loading ? <LoadingBar /> :
                    <div className="main-content profile-details">
                        <Row>
                            <Col sm={12} md={6}>
                                <h3 className="mb-3">Your Profile</h3>
                                {error && <ErrorAlert messages={error} /> }
                                {!error && <ContactDetails contactDetails={profile.contactDetails} userID={userID} />}
                            </Col>
                        </Row>
                        {!error && <ScoreDetails title="Robin Score" scoreDetails={profile.scoreDetails} />}
                        <Row>
                            <Col sm={12} md={4}>
                                <Button variant="secondary" onClick={() => this.props.history.push('/profile/scorehistory')} block>View Robin Score History</Button>
                                <ScoreInfo />
                            </Col>
                        </Row>
                    </div>
                }
            </React.Fragment>
        )
    }
}

export default Profile;