import React, { Fragment } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import Breadcrumb from '../../common/Breadcrumb';
import LoadingBar from '../../common/LoadingBar';
import Confirm from '../../common/modals/Confirm';
import { formatDate } from '../../../Utilities';
import { OrderReference, ProductDetails, Details, ChangeHistory, SuccessMessage, ErrorMessage } from '../shared/Snippets';
import Endpoints from '../../common/Endpoints';
import { GET, PUT } from '../../../Consumer';

import '../shared/ReturnDetails.scss';

const CANCEL_SUCCESS_TITLE = "Return Cancelled Successfully";
const CANCEL_SUCCESS_TEXT = "This return has been cancelled and will no longer be collected.";
const CANCEL_ERROR_TITLE = "Return Cancel Failed";
const CANCEL_ERROR_TEXT = "Unable to cancel the return at this time.";

const CustomerDetails = ({ emailAddress, courierService, returnAddress }) => (
    <section>
        <h4>Customer Details</h4>
        <p className="title">Email Address</p>
        <p>{emailAddress}</p>
        { 
            returnAddress && (courierService === "Home Collection" &&
                <Fragment>
                        <p className="title">Collection Address</p>
                        <p>{returnAddress}</p>
                </Fragment>
            )
        }
    </section>
);

const CancelReturn = ({ collectionDate, handleShowHideModal }) => (
    <React.Fragment>
        <section className="mb-0">
            <p className="font-weight-bold">Need to cancel this customers return?</p>
            <p>{`You can cancel your return before the ${formatDate(collectionDate)}. We will inform the customer that the return has been cancelled.`}</p>
        </section>
        <Row>
            <Col sm={12} md={4} lg={2}>
                <Button variant="outline-danger" onClick={handleShowHideModal}>Cancel Return</Button>
            </Col>
        </Row>
    </React.Fragment>
);

class ReturnDetails extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            returnId: props.match.params.id || null,
            showModal: false,
            returnDetails: {},
            showCancelSuccess: false,
            showCancelFailure: false,
            viewStatusHistory: false
        };
    }

    async componentDidMount() {
        await this.getReturn();
        this.setState({ loading: false });
    }

    getReturn = () => {
        const { returnId } = this.state;
        return GET(Endpoints.RETURN.GET.RETAILER_RETURN_DETAILS + returnId)
            .then(response => response.json())
            .then(result => {
                const returnDetails = result.data;
                const error = returnDetails ? "" : "We are currently unable to retrieve details for this return.";
                this.setState({ returnDetails: returnDetails, error: error });
            });
    }

    cancelReturn = () => {
        const { returnId } = this.state;
        return PUT(`${Endpoints.RETURN.PUT.RETAILER_CANCEL}${returnId}/cancel`)
            .catch(error => console.log(error))
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                else {
                    this.setState({ showCancelFailure: true, showModal: false });
                }
            })
            .then(result => {
                const cancelSuccess = result.data ? true : false;
                this.setState({ showCancelSuccess: cancelSuccess, showCancelFailure: !cancelSuccess, showModal: false });
            });
    }

    handleShowHideModal = () => this.setState((prevState) => ({ showModal: !prevState.showModal }));

    handleCancelReturn = async () => {
        this.setState({ loading: true });
        await this.cancelReturn();
        await this.getReturn();
        this.setState({ loading: false });
    }

    handleViewStatusHistory = async () => {
        this.setState({ viewStatusHistory: true });
    }

    handleCloseStatusHistory = async () => {
        this.setState({ viewStatusHistory: false });
    }

    render() {

        const { returnDetails, viewStatusHistory, loading, showModal, showCancelSuccess, showCancelFailure } = this.state;
        
        return (
            <React.Fragment>
                {loading ? <LoadingBar /> :
                viewStatusHistory 
                    ? <ChangeHistory isRetailer={true} changeHistory={returnDetails.changeHistory} 
                        close={this.handleCloseStatusHistory} 
                        retailer={returnDetails.selectedRetailer} 
                        orderNumber={returnDetails.orderID}/> 
                    : <React.Fragment>
                        <div className="main-content return-details">
                            <Breadcrumb link="/retailer/return" text="Back to Returns" />
                            <h3 className="mb-3">Return Information</h3>
                            {showCancelSuccess && <SuccessMessage heading={CANCEL_SUCCESS_TITLE} message={CANCEL_SUCCESS_TEXT} />}
                            {showCancelFailure && <ErrorMessage heading={CANCEL_ERROR_TITLE} message={CANCEL_ERROR_TEXT} />}
                            <CustomerDetails emailAddress={returnDetails.emailAddress} courierService={returnDetails.courierService} returnAddress={returnDetails.returnAddress} />
                            <OrderReference orderReference={returnDetails.orderReference} orderID={returnDetails.orderID} />
                            <ProductDetails products={returnDetails.products} returnReason={returnDetails.returnReason} 
                                additionalInformation={returnDetails.additionalInformation} />
                            <Details 
                                trackingReference={returnDetails.trackingReference}
                                postcode={returnDetails.postcode}
                                collectionDate={returnDetails.collectionDate}
                                returnRobinId={returnDetails.returnRobinId}
                                createdDate={returnDetails.createdDate}
                                courier={returnDetails.courier}
                                courierService={returnDetails.courierService}
                                status={returnDetails.status} />
                            {returnDetails.changeHistory.length > 0 ? <Button variant="link" className="p-0  underline" 
                                onClick={this.handleViewStatusHistory}>View Status History</Button> : null} 
                            {returnDetails.canCancel && <CancelReturn collectionDate={returnDetails.collectionDate} 
                                handleShowHideModal={this.handleShowHideModal} />}
                        </div>
                        <Confirm title="Cancel Customers Return Request" variant="outline-danger" linkText="Return and Don't Cancel" 
                            block="true" buttonText="Cancel Return" text={`Are you sure you want to cancel this return?`} 
                            show={showModal} handleClose={this.handleShowHideModal} handleConfirmAction={this.handleCancelReturn} closeLink={true} />
                    </React.Fragment>
                    }
            </React.Fragment>
        );
    }
}

export default ReturnDetails;