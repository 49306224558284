import React from 'react';
import { Row, Col, Button, InputGroup, FormControl } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { RETURN_METHODS } from '../../common/constants';

const RetailerReturnsFilter = ({ filter, sort, onFilterChange, onSearch, onClear, handleKeyPress, handleIconClicked }) => {
    return (
        <React.Fragment>
            <Row className="form-group-filter">
                <Col xs={{ span: 6 }} sm={{ span: 6 }} md={{ span: 2 }}>
                    <InputGroup >
                        <FormControl id="searchField" type="text" name="searchField" placeholder='Search' value={filter.searchField} onChange={onFilterChange} onKeyDown={handleKeyPress} />
                            <InputGroup.Append>
                                <InputGroup.Text id="searchBox" className="search-icon">
                                    <FontAwesomeIcon icon={faSearch} onClick={handleIconClicked} />
                                </InputGroup.Text>
                            </InputGroup.Append>
                    </InputGroup>
                </Col>
                <Col xs={{ span: 6 }} sm={{ span: 6 }} md={{ span: 2 }}>
                    <FormControl as="select" name="sort" value={sort} onChange={onFilterChange}>
                        {filter.orderingList.map((i) => <option value={i.id}>{i.name}</option>)}
                    </FormControl>
                </Col>
                <Col xs={{ span: 6 }} sm={{ span: 6 }} md={{ span: 2 }}>
                    <FormControl as="select" name="returnMethod" value={filter.returnMethod} onChange={onFilterChange}>
                        <option value="">Filter by Service</option>
                        {RETURN_METHODS.map((rm, i) => <option value={i}>{rm}</option>)}
                    </FormControl>
                </Col>
                <Col xs={{ span: 6 }} sm={{ span: 6 }} md={{ span: 2 }}>
                    <FormControl as="select" name="ordering" value={filter.ordering} onChange={onFilterChange} disabled={!filter.isReturnMethodSelected}>
                        <option value="">Collection/Drop-Off Date</option>
                        {filter.orderingListByMethod.map((i) => <option value={i.id}>{i.name}</option>)}
                    </FormControl>
                </Col>
                <Col xs={{ span: 6 }} sm={{ span: 6 }} md={{ span: 2 }}>
                    <FormControl as="select" name="status" value={filter.status} onChange={onFilterChange} disabled={!filter.isReturnMethodSelected}>
                        <option value="">Filter by Status</option>
                        {filter.statusesList.map((i) => <option value={i.id}>{i.name}</option>)}
                    </FormControl>
                </Col>
            </Row>
            <div className="button-group-filter mb-3 d-none d-md-block">
                <Button variant="primary" className="float-right ml-3" onClick={onSearch}>Apply Filters</Button>
                <Button variant="secondary" className="float-right" onClick={onClear}>Clear Filters</Button>
            </div>
            <Row className="mb-3 d-md-none d-lg-none d-xl-none">
                <Col xs={12} sm={12}>
                    <Button variant="primary" className="mb-0" onClick={onSearch}>Apply Filters</Button>
                </Col>
                <Col xs={12} sm={12}>
                    <Button variant="secondary" onClick={onClear}>Clear Filters</Button>
                </Col>
            </Row>
        </React.Fragment>)};

export {
    RetailerReturnsFilter
}