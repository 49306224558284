// Bank account types
export const BANK_ACCOUNT_TYPES = [
    { id: "bacs_debit", name: "BACS" }
];

// Sub nav
export const PAYMENTS_SUB_NAV = [
    { text: "Card Payment", link: "/retailer/settings/payments" },
    { text: "Direct Debit", link: "/retailer/settings/payments/directdebit" }
];

export const PAYMENTS_SUB_NO_DD_NAV = [
    { text: "Card Payment", link: "/retailer/settings/payments" }
];

export const RETURN_METHODS = ["Home Collection", "Drop-off Point"];

export const STATUSES_HOME_COLLECTION = [
    { id: "Pending", name: "Pending" },
    { id: "Completed", name: "Completed" },
    { id: "InProcess", name: "In Process" },
    { id: "FailedCollection", name: "Failed Collection" },
    { id: "RetailerRejected", name: "Retailer Rejected" },
    { id: "Failed", name: "Failed" },
    { id: "Cancelled", name: "Cancelled" },
];

export const STATUSES_DROPOFF_RETAILER = [
    { id: "AwaitingDropoff", name: "Awaiting Drop-off" },
    { id: "DropoffSuccessful", name: "Drop-off Successful" },
    { id: "Arrived", name: "Arrived" },
    { id: "Expired", name: "Expired" },
    { id: "Failed", name: "Failed" }
];

export const STATUSES_DROPOFF_CUSTOMER = [
    { id: "AwaitingDropoff", name: "Awaiting Drop-off" },
    { id: "DropoffSuccessful", name: "Drop-off Successful" },
    { id: "Expired", name: "Expired" },
    { id: "Failed", name: "Failed" }
];

export const ORDERING_FILTER_HOME_COLLECTION = [
    { id: "CollectionDateDesc", name: "Collection Date - Newest to Oldest" },
    { id: "CollectionDateAsc", name: "Collection Date - Oldest to Newest" }
];

export const ORDERING_FILTER_DROPOFF = [
    { id: "DropOffDateDesc", name: "Drop-off Date - Newest to Oldest" },
    { id: "DropOffDateAsc", name: "Drop-off Date - Oldest to Newest" }
];

export const ORDERING_FILTER_DEFAULT = [
    { id: "DateCreatedDesc", name: "Date Created - Newest to Oldest" },
    { id: "DateCreatedAsc", name: "Date Created - Oldest to Newest" }
];