import React, { Component } from 'react';
import { Form, FormGroup, Button } from 'react-bootstrap';
import 'react-intl-tel-input/dist/main.css';
import { ErrorAlert } from '../common/Alerts';
import FormValidator from '../common/FormValidator';
import { POST_ASYNC } from '../../Consumer';
import Endpoints from '../common/Endpoints';
import '../profile/shared/Profile.scss';
import Mobile from '../common/Mobile';
import { setCaretPosition, isEmpty } from '../../Utilities';
import { PHONE_UTIL } from '../register/shared/Snippets';

class EditMobileNumber extends Component {

    constructor(props) {
        super(props);

        this.validator = new FormValidator([
            {
                field: 'phoneNumber',
                method: 'isEmpty',
                validWhen: false,
                message: 'This is not a valid mobile number, try again.'
            }
        ]);

        this.state = {
            form: {
                phoneNumber: '',
                confirmation: false
            },
            userId: this.props.location.userID !== undefined ? this.props.location.userID : undefined,
            validation: this.validator.valid(),
            warning: false,
            errorMessages: [],
            heading: '',
            submitted: false,
            loading: false,
            confirmationValidation: true,
        };
    }

    handleInputChange = (e) => {
        let { name, value } = e.target;

        if (e.target.getAttribute('parent')) {
            let parent = e.target.getAttribute('parent');
            let parentValue = this.state.form[parent];
            parentValue[name] = value;

            name = parent;
            value = parentValue;
        }

        this.setState(prevState => ({
            ...prevState,
            form: {
                ...prevState.form,
                [name]: value
            }
        }));
    }

    validate = (form) => {
        let validation = this.validator.validate(form);

        if (!isEmpty(form.phoneNumber)) {
            try {
                const phone = PHONE_UTIL.parse(form.phoneNumber, form.countryCode);
                if (!PHONE_UTIL.isValidNumber(phone)) {
                    validation.isValid = false;
                    validation.phoneNumber.isInvalid = true;
                    validation.phoneNumber.message = "This is not a valid mobile number, try again.";
                }
                else if(PHONE_UTIL.getNumberType(phone) !== 1){
                    validation.isValid = false;
                    validation.phoneNumber.isInvalid = true;
                    validation.phoneNumber.message = "This is not a valid mobile number, try again.";
                }
            } catch (e) {
                console.log(e);
                validation.isValid = false;
                validation.phoneNumber.isInvalid = true;
                validation.phoneNumber.message = "This is not a valid mobile number, try again.";
            }
        }
        return validation;
    }

    handlePhoneNumberChange = (...args) => {
        setCaretPosition(args[3].length);

        this.setState(prevState => ({
            ...prevState,
            form: {
                ...prevState.form,
                ...{
                    phoneNumber: args[3],
                    countryCode: args[2].iso2
                }
            }
        }));
    }

    handleSubmit = async (e) => {
        e.preventDefault();
        this.setState({ loading: true });

        const { form } = this.state;
        const validation = this.validate(form);
        this.setState({ validation: validation, submitted: true });

        if (validation.isValid) {
            const vm = {
                UserID: this.state.userId,
                MobileNumber: this.state.form.phoneNumber
            };

            await POST_ASYNC(Endpoints.USER.POST.VERIFY_MOBILE_NUMBER, vm)
                .then(response => response.json())
                .then((result) => {
                    if (result.error) {
                        if (result.data !== null) this.setState({ warning: result.data.warning ?? false, errorMessages: result.data.errorMessages ?? [], loading: false });
                        else this.setState({ errorMessages: result.message, loading: false });
                    }
                    else this.props.history.push({ pathname: "/profile/mobilenumberverification", state: { userId: result.data.id }});
                })
                .catch(() => this.setState({ errorMessages: ["There has been an issue during submission."], loading: false }));
        } else this.setState({ loading: false });
    }

    render() {
        let { errorMessages, heading, validation, form } = this.state;

        return (
            <Form onSubmit={this.handleSubmit}>
                <div className="main-content editMobile-number">
                        <h3 className="mb-3">Your Profile</h3>
                        {errorMessages.length > 0 && <ErrorAlert messages={errorMessages} heading={heading} />}
                        <h4>Edit Mobile Number</h4>
                        <FormGroup className='mb-5'>
                            <Mobile value={form.phoneNumber} handlePhoneNumberChange={this.handlePhoneNumberChange} invalidPhoneNumber={validation.phoneNumber.isInvalid}  validationMessage={validation.phoneNumber.message} />

                            <div className='button-container'>
                                <Button variant="secondary" onClick={() => this.props.history.push('/profile')}>Cancel</Button>                       
                                <Button variant="primary" type="submit" value="Submit">Save</Button>                  
                            </div>                         
                        </FormGroup>
                </div>
            </Form>
        )
    }
}

export default EditMobileNumber;