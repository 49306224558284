import React, { Fragment, useCallback } from 'react';
import { FormGroup, FormLabel } from 'react-bootstrap';
import CustomDatePicker from '../../../common/inputs/CustomDatePicker';

let startDate =  new Date();
//Collection can not be today or tomorrow
startDate.setDate(startDate.getDate() + 2);
let endDate  = new Date();
//2weeks set for the collection
endDate.setDate(endDate.getDate() + 16);

const CreateReturnDetails = ({ returnDetails, returnValidation, handleReturnDetailsInputChange,  bankHolidays }) => {
    const isNotSundayOrBankHoliday = useCallback((date) => {
        const dateAsString = date.toDateString();
        const day = date.getDay();
        return day !== 0 && !bankHolidays.includes(dateAsString);
    },[]);

    const setDefaultDate = () => {
        //Collection can not be today or tomorrow
        startDate= new Date().setDate(new Date().getDate() + 2);
        //2weeks set for the collection
        endDate= new Date().setDate(new Date().getDate() + 16);
        return (startDate, endDate);
    };

    return (<Fragment>
        <h4 className="mt-2 mb-3">Return Details</h4>
        <FormGroup>
            <FormLabel htmlFor="collectionDateField">Collection Date</FormLabel>
                <CustomDatePicker
                    errorClass={returnValidation.collectionDate.isInvalid ? "input-error" : ""}
                    filterDate={isNotSundayOrBankHoliday}
                    setDefaultDates={setDefaultDate}
                    minDate={startDate}
                    maxDate={endDate}
                    selected={returnDetails.collectionDate}
                    handleInputChange={handleReturnDetailsInputChange}
                />
            <span className="text-danger">{returnValidation.collectionDate.message}</span>
        </FormGroup>
    </Fragment>)
}


export default CreateReturnDetails;